import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import useLocalStorage from "./components/hooks/UseLocalStorage";
import axios from "axios";
import { useParams } from 'react-router';

import ApiEndpoints from "./constants/ApiEndpoints";

// page ELEMENTS
import Header from "./pages/elements/Header";
import Footer from "./pages/elements/Footer";

// PAGES
import ContentPage from "./pages/ContentPage";
import LogIn from "./components/authentication/Login";

// PAGES, charts
import AgencyOverview from "./pages/AgencyOverview";
import AgencySitrepInput from "./pages/AgencySitrepInput";
import Dashboard from "./pages/Dashboard";
import FiresCharts from "./pages/FiresCharts";
import Inventory from "./pages/Inventory";
import InventoryInput from "./pages/InventoryInput";
import NationalFires from "./pages/NationalFires";
import NationalSitRep from "./pages/NationalSitRep";
import NationalSitRepArchive from "./pages/NationalSitRepArchive";
import NationalSitrepInput from "./pages/NationalSitrepInput";
import Summary from "./pages/Summary";

// page STYLES
import './styles/Normalize.css';
import './styles/Base.css';
import './styles/Layout.css';
import './styles/Responsive.css';
import './styles/Print.css';

function App() {

  const userSessionTTL = process.env.REACT_APP_CACHE_IN_SECONDS_USER_SESSION_LENGTH || false;
  const [isLoggedIn, setLoggedIn] = useLocalStorage("is-logged-in", false, userSessionTTL); // use as FLAG to show if anyone is logged in or not, to display different content or components
  const [activeUser, setActiveUser] = useLocalStorage("active-user", false, userSessionTTL); // stores the logged in user's JWT and profile data, as provided by API via auth

  // vars and funcs, for PUBLISHED CONTENT from backend, used to turn fixed-pages ON-OR-OFF in menu/routing

  const contentPublishedTTL = process.env.REACT_APP_CACHE_IN_SECONDS_STRAPI_PAGES || false;
  const [contentPublished, setContentPublished] = useLocalStorage("cache-pages-published", false, contentPublishedTTL);

  useEffect(() => {
      if ( contentPublished===false ) {
          let url = ApiEndpoints.contentStaticPublished();

          axios
          .get( url )
          .then(({ data }) => {

              // create ARRAY of just the published ALIASES, so we can easily check against them...
              let publishedAliases = [];
              if ( data.hasOwnProperty("data") ) {
                  publishedAliases = data.data.map(a => a.attributes.Alias);
              }
              setContentPublished( publishedAliases );

          })
          .catch((error) => {
              console.log(error)
          })
      }
    }, []);

  return (
    <Suspense fallback="loading">
      <BrowserRouter>

        { /* list of ROUTES that should NOT include the HEADER, useful for EMBEDs. Use a spacer DIV instead. */ }

        <Route 
          render={({ location }) => ['/map'].includes(location.pathname)
            ? <div style={{height:'8px'}} />
            : <Header isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} contentPublished={contentPublished} />
          }
        />

        <main>
        
          <Switch>
            { /* for practical reasons, we're currently NOT ALLOWING the homepage(s) to be UNPUBLISHed via the STRAPI system */ }
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/dashboard" component={Dashboard} />
            
            { /* dashboard WITHOUT header (see render check above) for use on CIFFC.CA website as embed */ }
            <Route exact path="/map" component={(props) => <Dashboard isEmbedded={true} />} />

            { /* CMS pages already use a publish/unpublish state, so we only need to list whichever we receieved from the API */ }
            <Route exact path="/page" component={Summary} />
            <Route exact path="/page/:pageAlias" component={ContentPage} />

            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("national-summary")
                    ? <Route path="/summary"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/summary" component={Summary} />
            
            { /* for practical reasons, we're currently NOT ALLOWING the homepage(s) to be UNPUBLISHed via the STRAPI system */ }
            <Route exact path="/agency" render={(props) => <AgencyOverview {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />} />
            
            { /* CMS pages already use a publish/unpublish state, so we only need to list whichever we receieved from the API */ }
            <Route exact path="/agency/page" render={(props) => <AgencyOverview {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />} />
            <Route exact path="/agency/page/:pageAlias" render={(props) => <ContentPage {...props} isLoggedIn={isLoggedIn} activeUser={activeUser} />} />
            
            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("inventory")
                    ? <Route path="/agency/inventory"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/agency/inventory" render={(props) => <Inventory {...props} isLoggedIn={isLoggedIn} activeUser={activeUser} />} />

            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("inventory-input")
                    ? <Route path="/agency/inventory/input"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/agency/inventory/input" render={(props) => <InventoryInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />} />
            
            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("sitrep-input-agency")
                    ? <>
                        <Route path="/agency/sitrep/*"><Redirect to="/page/404-page-not-found" /></Route>
                        <Route path="/agency/sitrep"><Redirect to="/page/404-page-not-found" /></Route>
                      </>
                    : null
            }
            <Route exact path="/agency/sitrep/:agencyParam/published" render={(props) => <AgencySitrepInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} getSitrepType='published' />} />
            <Route exact path="/agency/sitrep/:agencyParam/approved" render={(props) => <AgencySitrepInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} getSitrepType='approved' />} />
            <Route exact path="/agency/sitrep/:agencyParam" render={(props) => <AgencySitrepInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} getSitrepType='draft' />} />
            <Route exact path="/agency/sitrep" render={(props) => <AgencySitrepInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />} />
            
            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("sitrep-input-national")
                    ? <Route path="/agency/national"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/agency/national" render={(props) => <NationalSitrepInput {...props} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />} />

            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("national-sitrep-archive")
                    ? <>
                        <Route path="/situation/archive"><Redirect to="/page/404-page-not-found" /></Route>
                        <Route path="/situation/*"><Redirect to="/page/404-page-not-found" /></Route>
                        <Route path="/situation"><Redirect to="/page/404-page-not-found" /></Route>
                      </>
                    : null
            }
            <Route exact path="/situation/archive" component={NationalSitRepArchive} />
            <Route exact path="/situation/:routerParamDate" render={(props) => <NationalSitRep {...props} isLoggedIn={isLoggedIn} activeUser={activeUser} />} />
            <Route exact path="/situation" render={(props) => <NationalSitRep {...props} isLoggedIn={isLoggedIn} activeUser={activeUser} />} />
            
            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("national-fires")
                    ? <Route path="/national"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/national" component={NationalFires} />

            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("fire-statistics")
                    ? <Route path="/statistics"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route exact path="/statistics" component={FiresCharts} />

            {
                // in content UNPUBLISHED, redirect route to NOT FOUND (note that content ALIASes may not match URL aliases, due to client changes)
                contentPublished===false || !contentPublished.includes("login")
                    ? <Route path="/login"><Redirect to="/page/404-page-not-found" /></Route>
                    : null
            }
            <Route path="/login">
              <LogIn setActiveUser={setActiveUser} setLoggedIn={setLoggedIn} />
            </Route>
            
            { /* REDIRECTS, based on DRUPAL urls */ }

            <Route path={["/en/national-fires", "/fr/national-fires"]}><Redirect to="/national" /></Route>
            <Route path={["/ciffc/fire-summary", "/en/ciffc/fire-summary", "/fr/ciffc/fire-summary"]}><Redirect to="/summary" /></Route>

            <Route path={["/en/daily-fires", "/fr/node/10"]}><Redirect to="/statistics" /></Route>
            <Route path={["/en/fires-by-year", "/fr/node/11"]}><Redirect to="/statistics" /></Route>
            <Route path={["/en/hectares-by-year", "/fr/node/12"]}><Redirect to="/statistics" /></Route>
            <Route path={["/en/fires-this-week", "/fr/node/14"]}><Redirect to="/statistics" /></Route>
            <Route path={["/en/fires-by-week-vs-avg", "/fr/node/15"]}><Redirect to="/statistics" /></Route>
            <Route path={["/en/fires-by-week-vs-last-year", "/fr/node/16"]}><Redirect to="/statistics" /></Route>

            <Route path={["/ciffc/sitrep", "/en/ciffc/sitrep", "/fr/ciffc/sitrep"]}><Redirect to="/situation" /></Route>
            <Route path={["/ciffc/public/sitrep", "/en/ciffc/public/sitrep", "/fr/ciffc/public/sitrep"]}><Redirect to="/situation" /></Route>
            <Route path={["/en/sitrep-archive", "/fr/sitrep-archive"]}><Redirect to="/situation/archive" /></Route>
            <Route path={["/en/sitrep-archive?date=:archiveMonth", "/fr/sitrep-archive?date=:archiveMonth"]}><Redirect to="/situation/archive" /></Route>

            <Route path={["/en/user/login", "/fr/user/login"]}><Redirect to="/login" /></Route>

            <Route path={["/en/user", "/fr/user"]}><Redirect to="/agency" /></Route>

            <Route path={["/en/national-inventory", "/fr/national-inventory"]}><Redirect to="/agency/inventory" /></Route>

            <Route path={["/ciffc/national-sitrep-input", "/en/ciffc/national-sitrep-input", "/fr/ciffc/national-sitrep-input"]}><Redirect to="/agency/national" /></Route>
            <Route path={["/ciffc/sitrep-input/:agencyCode", "/en/ciffc/sitrep-input/:agencyCode", "/fr/ciffc/sitrep-input/:agencyCode"]}><Redirect to="/agency/sitrep" /></Route>
            <Route path={["/ciffc/sitrep-input/nojs/:agencyCode", "/en/ciffc/sitrep-input/nojs/:agencyCode", "/fr/ciffc/sitrep-input/nojs/:agencyCode"]}><Redirect to="/agency/sitrep" /></Route>
            
            <Route path={["/en/active-fires-map", "/fr/node/8"]}><Redirect to="/" /></Route>
            <Route path={["/en", "/fr"]}><Redirect to="/" /></Route>
            
            { /* UPLOADS folder in Strapi CMS */ }
            <Route path='/uploads/*' component={() => {
                const file = useParams("*")[0];
                window.location.href = process.env.REACT_APP_STRAPI_BASE_URL + "/uploads/" + file;
                return null;
            }}/>
            
            { /* CATCHALL page-not-found error */ }

            <Route path="*"><Redirect to="/page/404-page-not-found" /></Route>

          </Switch>

        </main>
        <Footer />

      </BrowserRouter>
    </Suspense>
  );
}

export default App;
