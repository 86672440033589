import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useDeepCompareEffect from 'use-deep-compare-effect';
import axios from "axios";
import moment from 'moment-timezone';
import _ from 'lodash'; // https://lodash.com/docs

// toast, made available throughout app
import toast, { Toaster } from "react-hot-toast";

import ResourceOptionSet from "../components/forms/ResourceOptionSet";
import ResourceOptionsFilters from "../constants/ResourceOptionsFilters";

import AgenciesData from "../constants/AgenciesData";
import FormStatuses from "../constants/FormStatuses"; 
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "./elements/StaticContent";

var source; // used for CANCEL REQUESTs ?

function AgencySitrepInput( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // INTERVAL amount (in milliseconds) for PING and SYNC
    const intervalAmount = 5 * 1000; // 5 seconds

    // is API access available?
    const [isApi, setIsApi] = useState(null);
    
    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;
    const [ isCiffc, setIsCiffc] = useState(false);

    // define AGENCY CODE to use for GET/POST, either fixed from agency JWT or passed in if allowed by permissions...
    const { agencyParam } = useParams();
    const [ agencyCode, setAgencyCode] = useState( agencyParam );

    // REDIRECT browser out of authenticated area if user not LOGGED IN

    const history = useHistory();

    useEffect(() => {

        // recognize if LOGGED in

        if (
            !isLoggedIn
            || !activeUser
            || activeUser.user === undefined
            || activeUser.role === undefined
        ) {
            history.push("/login");
        } else {

            // figure out AGENCY CODE associated with logged-in user (if any)

            if ( 
                // and has a recognized AUTH role
                activeUser.user.role
                && activeUser.user.role.name
                && activeUser.user.role.name.toUpperCase() === 'AUTHENTICATED'

                // and has a recognized AGENCY code
                && activeUser.user !== undefined 
                && activeUser.user.agency_code
                && AgenciesData.hasOwnProperty( activeUser.user.agency_code.toLowerCase() )
            ) {
                setAgencyCode( activeUser.user.agency_code );
            }

            // recognize if logged in as CIFFC role

            else if (
                activeUser
                && activeUser.role !== undefined 
                && activeUser.role.name !== undefined 
                && activeUser.role.name.toUpperCase() === 'CIFFC'
            ) {
                setIsCiffc(true);
            }

            // if FAILed both tries in login check...

            else {
                history.push("/agency");
            }

        }   

    }, [ isLoggedIn, activeUser ]);

    // TRACK form status (allowances, permissions, errors)

    const initialFormStatus = {
        versionNumber: false, // introduced FEB 2025, to visually track draft/approved/published copies
        isLocked: true,
        isDipFed: false,
        isSyncing: false,
        isError: false,
        displayStatus: null,
        displayLastUpdated: null,
        lastEditedBy: 0,
        lastClaimedBy: 0,
        validationErrors: {}
    }

    const getSitrepType = props.getSitrepType || 'draft';
    const [formStatus, setFormStatus] = useState(initialFormStatus);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // is IS-EDITING changes, always refresh with the LATEST sitrep data (note, this will PURPOSELY trigger on initial load)
        console.log('UE isEditing');
        sitrepGet();
    }, [ isEditing ]);

    // INITIALIZE data to send/sync with API

    const initialFormData = {
        // A: Agency Operational Summary
            en_ops_summary : '', 
            fr_ops_summary : '',
        // B: Fire Statistics
            // New Wildland Fires Yesterday
                a_ltg: '0',
                a_hum: '0',
                a_oth: '0', 
            // Full Response
                b_oc: '0',
                b_bh: '0',
                b_uc: '0',
                b_active_hectares: '0',
                b_total_out: '0',
                b_out_hectares: '0',
            // Modified Response
                c_act: '0',
                c_out: '0',
                c_total_hectares: '0',
            // Monitored Response
                c2_act: '0',
                c2_out: '0',
                c2_total_hectares: '0',
            // Prescribed Fires
                e_act: '0',
                e_out: '0',
                e_total_hectares: '0',
        // C: Priority Wildland Fires
            priority_fires: [],
        // D: Fire Occurence Predition for Tomorrow
            fop_ltg: '',
            fop_nat: '',
        // E: Agency Prepardness Level
            current_fire_hazard: '0',
            current_fire_load: '0',
            expected_fire_load: '0',
            agency_resource_levels: '0',
            ciffc_response_capacity: '0',
            international: '0',
            prep_level_override: '0',
            en_prep_comment: '', 
            fr_prep_comment: '',
        // G: Casual Hire Helicopters
            chh_light: '0',
            chh_int: '0',
            chh_med: '0',
            chh_heavy: '0',
        // H: Compact Agreement Mobilization
            mobs: [],
            mobs_grouped: [],
        // I: Compact Agreement Demobilization
            demobs: [],
        // J: Resource Availability Report
            rars: [],
        // Duty Officer
            duty_officer: '',
            duty_officer_24_hour_cell: ''
        // 
    }

    const initialDataPriorityFires = {
        fires: '',
        latitude: '',
        longitude: '',
        stage_of_control: '',
        size: '',
        incident_type: '',
        percent_contained: '',
        comments_en: '',
        comments_fr: ''
    };

    const initialDataMobs = {
        to: '',
        resources: '',
        from: ''
    };

    /*
    
    // SECTION I completed removed for agencies, as requested by client

    const initialDataDemobs = {
        to: '',
        resources: '',
        from: ''
    };

    */

    const initialDataRars = {
        available: '',
        resources: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [priorityFiresData, setPriorityFiresData] = useState({});

    const [resourceData, setResourceData] = useState();
    const [resourceOptionsExpanded, setResourceOptionsExpanded] = useState();
    // const [resourceOptionsShort, setResourceOptionsShort] = useState();

    // define SITREP GET from server, as we will reuse this in more than one place...
    const sitrepGet = ( triggerHandleBlurAfter=false ) => {

        console.log( 'sitrepGet() CALLED' );

        setFormStatus({
            ...formStatus, 
            isSyncing: true
        });

        let urlGet = ApiEndpoints.dipSitrepGetDraft() + '?agency=' + agencyCode;
        if ( getSitrepType==='approved' ) urlGet = ApiEndpoints.dipSitrepGetApproved() + '?agency=' + agencyCode;
        if ( getSitrepType==='published' ) urlGet = ApiEndpoints.dipSitrepGetPublished() + '?agency=' + agencyCode;

        axios
            .get(
                urlGet,
                {
                    headers: {
                        Authorization: "Bearer " + activeUser.jwt
                    }
                }
            )
            .then(({ data }) => {
                console.log( 'GET, data got', data, 'GET, for USER', activeUser );

                // populate PRIORITY FIREs option data, for DATALIST
                if ( data.fire_options ) { setPriorityFiresData(data.fire_options); }

                // GROUP data sets by KEY, for UI requirements...
                data.mobs_grouped = _.values(
                    _.groupBy( data.mobs, function(i) {
                        return i.to + ' ' + i.from;
                    })
                );

                let newFormData = { ...formData, ...data }

                // set BEFORE form data to newly fetched data
                formDataBefore.current = newFormData;

                // set FORM DATA from fetch...
                setFormData( newFormData );

                // figure out default status, and then determine DISPLAYABLE status from constant file
                let displayStatus = 'N';
                if ( data.status.trim() ) displayStatus = data.status.toUpperCase();
                if ( FormStatuses[displayStatus] ) displayStatus = FormStatuses[displayStatus].title;

                // ...and update FORM STATUS based on fetch...
                setFormStatus(
                    {
                        ...formStatus,
                        versionNumber: data.version_number,
                        isSyncing: false,
                        isDipFed: data.has_dip_feed,
                        isLocked: data.locked,
                        isError: false,
                        lastEditedBy: data.edited_by,
                        lastClaimedBy: data.claimed_by,
                        displayLastUpdated: data.last_edit,
                        displayStatus: displayStatus,
                    }
                );

                // flag API as accessible
                setIsApi(true);

                // ...finally, if the CURRENT LOGGED IN USER is recognized as the user the API reports as CURRENTLY-EDITING this sitrep, lets trigger the HANDLE CLAIM immediately...
                if (
                    parseInt(data.claimed_by)>0
                    && parseInt(data.claimed_by) === parseInt(activeUser.user.id)
                ) {
                    handleClaim(true);
                }


            })
            .catch((error) => {
                console.log( 'GET, error', error);

                // update FORM STATUS to display syncing is DONE...
                setFormStatus({
                    ...formStatus, 
                    isError: true,
                    isSyncing: false
                });

                // flag API as inaccessible, show WARNING
                setIsApi(false);
            });

    }

    // API endpoints data FETCH

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {

            // fetch INVENTORY definitions, to use in pulldowns...

            const urlResources = ApiEndpoints.dipResourceOptionsActive();

            axios
                .get(
                    urlResources
                )
                .then(({ data }) => {
                    setResourceData(data);
                })
                .catch((error) => {
                    console.log( 'GET, error', error);
                });
            
            /*
            axios
                .post(
	                urlResources,
	                JSON.stringify({shortnames: ResourceOptionsFilters.short}),
			        {
						headers: {
							// Overwrite Axios's automatically set Content-Type
							'Content-Type': 'application/json'
						}
			        }
                )
                .then(({ data }) => {
                    setResourceOptionsShort(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (resources, short)', error);
                });
            */

            axios
                .post(
	                urlResources,
	                JSON.stringify({shortnames: ResourceOptionsFilters.expanded}),
			        {
						headers: {
							// Overwrite Axios's automatically set Content-Type
							'Content-Type': 'application/json'
						}
			        }
                )
                .then(({ data }) => {
                    setResourceOptionsExpanded(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (resources, expanded)', error);
                });

        } else {
            setFormData( null );
        }

    }, [ isLoggedIn ]);

    // API endpoint data PING

    const [doSync, setDoSync] = useState(false);

    useEffect(() => {
        let interval = setInterval(() => {
            
            if ( 
                isLoggedIn 
                && isApi
                && agencyCode
                && formData!==null
            ) {

                if ( !isEditing ) {

                    const urlSitreps = ApiEndpoints.dipSitrepStates();
                    
                    axios
                        .get(
                            urlSitreps,
                            {
                                headers: {
                                    Authorization: "Bearer " + activeUser.jwt
                                }
                            }
                        )
                        .then(({ data }) => {
                            
                            // if SUCCESSFULLY pinged data...
                            data.forEach(( pingSitrep )=>{
                                
                                if (
                                    // if data includes THIS agency sitrep form status...
                                    pingSitrep.agency.toLowerCase() === agencyCode.toLowerCase()
                                ) {
                                    console.log('PING', pingSitrep);

                                    // ...and if our form was LOCKED due to another user...
                                    if (
                                        parseInt(formStatus.isLocked)>0
                                        && parseInt(formStatus.lastClaimedBy) !== parseInt(activeUser.user.id)

                                        // ...and the PING status reports it is NO LONGER locked by that user...
                                        && (
                                            formStatus.lastClaimedBy===null
                                            || parseInt(formStatus.lastClaimedBy)===0
                                            || !formStatus.lastClaimedBy
                                        )
                                    ) {
                                        console.log('PING, to unlock', pingSitrep);

                                        // ...do a sitrep GET to discover the latest FORM DATA (if any)...
                                        sitrepGet(true);
                                    }

                                    else {
                                        
                                        // ...otherwise just update the FORM STATUS
                                        setFormStatus(
                                            {
                                                ...formStatus,
                                                versionNumber: pingSitrep.version_number,
                                                lastClaimedBy: pingSitrep.claimed_by,
                                            }
                                        );

                                    }

                                }

                            });

                        })
                        .catch((error) => {
                            console.log(error);

                            // flag API as inaccessible, show WARNING
                            setIsApi(false);
                        });

                }

                else if ( doSync ) {
                    handleSync();
                }

            }

        }, intervalAmount );

        return () => { clearInterval(interval); };

    }, [
        isLoggedIn, isApi, agencyCode,
        formData, formStatus,
        isEditing, doSync
    ]);

    // prep FORM data, handlers, etc

    const formDataBefore = useRef(formData);

    const handleChange = (event) => {

        console.log( 'handleChange() CALLED' );

        // get a COPY of current form data and use LODASH to more easily change VALUEs

        let updatedFormData = formData;
        _.set( updatedFormData, event.target.name, event.target.value ); // using LODASH to parse a path of object keys, to set the appropriate value (useful in sub ARRAYs)

        // recognize if is PRIORITY FIRE selection that requires POPULATING related fields

        if ( event.target.name.includes("priority_fires[") && event.target.name.includes("].fires") ) {
            if ( event.target.value && priorityFiresData.hasOwnProperty(event.target.value) ) {
                
                const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );
                
                if ( isConfirmed ) {

                    const matchedPriorityFire = priorityFiresData[event.target.value];

                    // reduce NAME to teh add-another INDEX, so we can target the other associated fields...
                    
                    let addAnotherId = event.target.name;
                    addAnotherId = addAnotherId.replace("priority_fires[", "");
                    addAnotherId = addAnotherId.replace("].fires", "");
                    addAnotherId = addAnotherId.toString();

                    // set INPUT VALUEs (using LODASH) based on what's available

                    if ( matchedPriorityFire.hasOwnProperty('latitude') ) {
                        const priorityFireLatitude = "priority_fires["+addAnotherId+"].latitude";   
                        _.set( updatedFormData, priorityFireLatitude, matchedPriorityFire.latitude );
                    }

                    if ( matchedPriorityFire.hasOwnProperty('longitude') ) {
                        const priorityFireLongitude = "priority_fires["+addAnotherId+"].longitude";   
                        _.set( updatedFormData, priorityFireLongitude, matchedPriorityFire.longitude );
                    }

                    if ( matchedPriorityFire.hasOwnProperty('stage_of_control') ) {
                        const priorityFireStageOfControl = "priority_fires["+addAnotherId+"].stage_of_control";   
                        _.set( updatedFormData, priorityFireStageOfControl, matchedPriorityFire.stage_of_control );
                    }

                    if ( matchedPriorityFire.hasOwnProperty('size') ) {
                        const priorityFireSize = "priority_fires["+addAnotherId+"].size";   
                        _.set( updatedFormData, priorityFireSize, matchedPriorityFire.size );
                    }

                    if ( matchedPriorityFire.hasOwnProperty('incident_type') ) {
                        const priorityFireIncidentType = "priority_fires["+addAnotherId+"].incident_type";   
                        _.set( updatedFormData, priorityFireIncidentType, matchedPriorityFire.incident_type );
                    }

                    if ( matchedPriorityFire.hasOwnProperty('percent_contained') ) {
                        const priorityPercentContained = "priority_fires["+addAnotherId+"].percent_contained";   
                        _.set( updatedFormData, priorityPercentContained, matchedPriorityFire.percent_contained );
                    }

                }
            }
        }

        // finally, update FORM data using SETTER, to trigger any USE EFFECTS

        setFormData({...formData, ...updatedFormData});

        // and after a WAIT to ensure the change happened, then trigger the BLUR handler to submit...

        handleBlur();

    };

    const handleBlur = (event, forceSync=false, forceSubmit=false ) => {

        console.log( 'handleBlur() CALLED' );

        if (
            isLoggedIn // no syncing back to API if not logged in
            && isApi // no syncing back if NO API access available
            && isEditing // no syncing back to API if we're in a view-only mode
            && getSitrepType === 'draft' // no syncing back to API if we're looking at APPROVED or PUBLISHED copies of a sitrep, ONLY DRAFT can be written to
        ) {

            // vanilla JS check if INPUT is valid based on input TYPE and PATTERN attributes first...
            if ( event && !event.target.checkValidity() ) {
                console.log('SYNC prevented, checkValidity() FAIL');

                let formErrors = formStatus.validationErrors;

                // try to determine best message to display
                let formErrorMessage = t('Invalid format');
                if ( event.target.type === 'number' ) { formErrorMessage = t('Invalid value.'); }
                if ( !event.target.value ) { formErrorMessage = t('This field is required.'); }

                // check for NON-REACT (hence lowercase) attribute indicating error to be displayed for ALTERNATE field
                if ( event.target.getAttribute('forinputname') ) {
                    _.set(formErrors, event.target.getAttribute('forinputname'), formErrorMessage);
                }
                // else set error for field MATCHING input name
                else {
                    _.set(formErrors, event.target.name, formErrorMessage);
                }

                // update FORM STATUS to display syncing is DONE...
                setFormStatus(
                    {
                        ...formStatus, 
                        isError: true,
                        validationErrors: formErrors,
                        checkValidity: 'here'
                    }
                );

                // TOAST
                toast.dismiss();
                toast.error(
                    t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                );

            }

            // only SYNC to API when someone is logged in AND when the form data has CHANGED since before the BLUR event
            else if (
                formDataBefore.current!==formData
                || forceSync // passed in PARAM that forces sync, even we we don't recognize the data as changed since last time
            ) {

                if ( forceSync ) { console.log('handleBlur, formChange pass BECAUSE FORCED'); }
                
                // ...assuming this clears at least the standard validity check (ABOVE), clear any error, trust API to let if still an issue
                if ( event ) {

                    // check for NON-REACT (hence lowercase) attribute indicating error to be cleared for ALTERNATE field
                    if ( event.target.getAttribute('forinputname') ) {
                        _.set(formStatus.validationErrors, event.target.getAttribute('forinputname'), null);
                    }
                    // else clear error for field MATCHING input name
                    else {
                        _.unset(formStatus.validationErrors, event.target.name);
                    }

                }

                // update BEFORE form data to match CURRENT form data
                formDataBefore.current = formData;
                
                if ( forceSubmit ) {
                    handleSync( forceSubmit );
                } else {
                    setDoSync(true);
                }
                
            } else {
                console.log('SYNC prevented, passed checkValidity() but formData not changed');
            }

        } else {

            console.log('SYNC prevented, logged='+isLoggedIn+', api='+isApi+' editing='+isEditing );

        }

    };

    // SYNC to server POST method here...

    const handleSync = ( forceSubmit=false ) => {

        console.log(' ');
        console.log('TO sync', formData, formStatus);
        console.log(' ');

        // update FORM STATUS to display syncing is STARTING...
        setFormStatus({
            ...formStatus, 
            isSyncing: true, 
            displayStatus: false,
        });

        const urlPost = ApiEndpoints.dipSitrepPost() + '?agency=' + agencyCode;

        let newFormStatus = {}

        if( source ){
            console.log("Cancel previous request");
            source.cancel();
        }
        
        source = axios.CancelToken.source();

        axios
            .post(
                urlPost,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + activeUser.jwt
                    },
                    cancelToken: source.token
                }
            )
            .then(({ data }) => {
                console.log("SYNCED, with response", data);

                // figure out default status, and then determine DISPLAYABLE status from constant file
                let displayStatus = 'N';
                if ( data.status.trim() ) displayStatus = data.status.toUpperCase();
                if ( FormStatuses[displayStatus] ) displayStatus = FormStatuses[displayStatus].title;

                newFormStatus = {
                    versionNumber: data.version_number,
                    isSyncing: false,
                    isLocked: data.locked,
                    isError: false,
                    lastEditedBy: data.edited_by,
                    lastClaimedBy: data.claimed_by,
                    displayLastUpdated: data.last_edit,
                    displayStatus: displayStatus,
                    validationErrors: data.validationErrors || {}
                }
                console.log('SYNCED, new form status', newFormStatus);

                // update FORM STATUS with new form STATUS on success
                setFormStatus({
                    ...formStatus,
                    ...newFormStatus
                });

                // try to TRIGGER a submit, if that's valid to do...
                setTriggerSubmit(forceSubmit);

                // TOAST
                toast.dismiss();
                toast.success(
                    t('Data synced with server.')
                );

            })
            .catch((error) => {
                
                if (axios.isCancel(error)){
                    // console.log("Canceled ", error); // cancelling will ALWAYS return an error as cancelling IS an error, so we can skip this
                }else if (
                    error.response !== undefined
                    && error.response.data !== undefined
                    && error.response.data.validationErrors !== undefined
                ) {
                    console.log("SYNCED, with error", error);

                    newFormStatus = {
                        isSyncing: false,
                        isError: true,
                        validationErrors: error.response.data.validationErrors
                    }

                    if ( error.response.data.hasOwnProperty('locked') ) { newFormStatus.isLocked = error.response.data.locked }
                    if ( error.response.data.hasOwnProperty('last_edit') ) { newFormStatus.displayLastUpdated = error.response.data.last_edit }
                    if ( error.response.data.hasOwnProperty('edited_by') ) { newFormStatus.lastEditedBy = error.response.data.edited_by }

                    if ( error.response.data.hasOwnProperty('status') ) { 
                        // figure out default status, and then determine DISPLAYABLE status from constant file
                        newFormStatus.displayStatus = 'N';
                        if ( error.response.data.status.trim() ) newFormStatus.displayStatus = error.response.data.status.toUpperCase();
                        if ( FormStatuses[newFormStatus.displayStatus] ) newFormStatus.displayStatus = FormStatuses[newFormStatus.displayStatus].title;
                    }
                    
                    newFormStatus = {
                        ...formStatus,
                        ...newFormStatus
                    }
                    
                    // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                    setFormStatus(newFormStatus);

                    // TOAST
                    toast.dismiss();
                    toast.error(
                        t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                    );

                }

                else {
                    console.log("SYNCED, with undisplayed/unknown error", error);

                    newFormStatus = {
                        ...formStatus,
                        isSyncing: false,
                        isError: true,
                        displayStatus: 'error'
                    }

                    // update with UNKNOWN error?
                    setFormStatus(newFormStatus);

                    // TOAST
                    toast.dismiss();
                    toast.error(
                        t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                    );

                }
            })
            .finally(() => {
                // update BEFORE form data to match CURRENT form data
                // formDataBefore.current = formData;

                // clear CANCEL PREVIOUS REQUEST token, as we have finished it
                source = false;
                
                // and let use be ready to SYNC again
                setDoSync(false);
            });

    };  

    // create generic SERIES add and remove methods, that would be ATTACHED (as onclicks) to related data in form data

    const seriesAddRow = (formDataKey, initialData) => {
        
        // we use LODASH to get and set to objects because it gives us flexibility on HOW DEEP IN THE OBJECT we want to change
        // JUL 2024, we switched object copying to LODASH deep cloning, to better ensure we don't have any shallow copying of objects happening, and ensure the various use effects recognize our changes

        // copy of related ARRAY to append to
        let addToSeries =  _.cloneDeep( _.get(formData, formDataKey) ); // let addToSeries = _.get(formData, formDataKey);

        // create UNIQUE (enough) key
        const newSeriesKey = formDataKey.replace('.','-') + '-' + Date.now();

        // appending to OBJECT passed in
        initialData.seriesKey = newSeriesKey;

        // appending to ARRAY series
        addToSeries.push(initialData);

        // updated STATE of form data
        let newFormData = {...formData};
        _.set(newFormData, formDataKey, addToSeries);
        setFormData(newFormData);

    };

    const seriesRemoveRow = (formDataKey, seriesKeyToRemove) => {

        const isConfirmed = window.confirm( t('Are you sure you want to delete this item?') );

        if ( isConfirmed ) {

            // we use LODASH to get and set to objects because it gives us flexibility on HOW DEEP IN THE OBJECT we want to change
            // JUL 2024, we switched object copying to LODASH deep cloning, to better ensure we don't have any shallow copying of objects happening, and ensure the various use effects recognize our changes

            let newFormData = _.cloneDeep(formData); // { ...formData }; 

            let removeFromSeries = _.cloneDeep( _.get(formData, formDataKey) ); // let removeFromSeries = _.get(formData, formDataKey);
            
            removeFromSeries = removeFromSeries.filter(x=>x.seriesKey!==seriesKeyToRemove);

            _.set( newFormData, formDataKey, removeFromSeries );

            setFormData(newFormData);

        }

    }

    // using CUSTOM HOOK to watch GROUPED SERIES, as default use-effect would "see" changes deeper than "surface level"...?

    useDeepCompareEffect(() => {

        formDataBefore.current = formData;
        
        // copy any GROUPED data sets back into normal object key, for SAVING to database...

        let newFormData = { ...formData };
        newFormData.mobs = _.flatMap(formData.mobs_grouped, x=>x);
        setFormData( newFormData );

        // ...and then let NORMAL react use-effect "see" that change to trigger DB save as needed

    }, [ 
        formData.mobs_grouped
    ]);

    // AFTER a change to SERIES, re-validate

    useEffect(() => {
        if ( isEditing ) {
            handleBlur(null);
        }
    }, [ 
        formData.priority_fires, 
        formData.mobs,
        formData.demobs, 
        formData.rars
    ]);

    // updated any READONLY calculated form values displayed...

    let initialFormCalculated = {
        a_total: 0,
        b_total_active: 0,
        b_total_fires: 0,
        b_total_area: 0.00,
        c_total: 0,
        c2_total: 0,
        d_total_fires: 0,
        d_total_area: 0.00,
        e_total: 0,
        prep_level_suggested: 1
    }

    const [formCalculated, setFormCalculated] = useState(initialFormCalculated);

    useEffect(() => {

        // PREP LEVEL calculated suggestion, based on RADIO inputs

        let prep_level_suggestion = 0;

        if (
            !formData.current_fire_hazard
            || !formData.current_fire_load
            || !formData.expected_fire_load
            || !formData.agency_resource_levels
            || !formData.ciffc_response_capacity
            // || !formData.international // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = t('Complete Choices Above');
        }

        // level 1
        if (
            parseInt(formData.current_fire_hazard) === 1
            && parseInt(formData.current_fire_load) === 1
            && parseInt(formData.expected_fire_load) === 1
            && parseInt(formData.agency_resource_levels) === 1
            && parseInt(formData.ciffc_response_capacity) === 1
            // && parseInt(formData.international) === 1 // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = 1;
        }

        // level 2
        if (
            parseInt(formData.current_fire_hazard) >= 1 && parseInt(formData.current_fire_hazard) <= 2
            && parseInt(formData.current_fire_load) >= 1 && parseInt(formData.current_fire_load) <= 2
            && parseInt(formData.expected_fire_load) === 2
            && parseInt(formData.agency_resource_levels) === 1
            && parseInt(formData.ciffc_response_capacity) === 2
            // && parseInt(formData.international) === 1 // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = 2;
        }

        // level 3
        if (
            parseInt(formData.current_fire_hazard) >= 2 && parseInt(formData.current_fire_hazard) <= 3
            && parseInt(formData.current_fire_load) >= 2 && parseInt(formData.current_fire_load) <= 3
            && parseInt(formData.expected_fire_load) === 3
            && parseInt(formData.agency_resource_levels) === 2
            && parseInt(formData.ciffc_response_capacity) >= 3
            // && parseInt(formData.international) === 1  // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = 3;
        }

        // level 4
        if (
            parseInt(formData.current_fire_hazard) >= 4
            && parseInt(formData.current_fire_load) >= 4
            && parseInt(formData.expected_fire_load) >= 4
            && parseInt(formData.agency_resource_levels) === 3
            && parseInt(formData.ciffc_response_capacity) >= 4
            // && parseInt(formData.international) === 2  // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = 4;
        }

        // level 5
        if (
            parseInt(formData.current_fire_hazard) >= 4
            && parseInt(formData.current_fire_load) >= 4
            && parseInt(formData.expected_fire_load) >= 4
            && parseInt(formData.agency_resource_levels) === 4
            && parseInt(formData.ciffc_response_capacity) === 5
            // && parseInt(formData.international) === 3  // Potenial For Internalation Assistance value removed, by client request
        ) {
            prep_level_suggestion = 5;
        }

        // backup PREP LEVEL calucation, if none of the above are met

        if ( !prep_level_suggestion ) {
            const prep_level_sum = parseInt(formData.current_fire_hazard) 
                + parseInt(formData.current_fire_load) 
                + parseInt(formData.expected_fire_load) 
                + parseInt(formData.agency_resource_levels) 
                + parseInt(formData.ciffc_response_capacity) 
                // + parseInt(formData.international)  // Potenial For Internalation Assistance value removed, by client request
            ;
            const prep_level_avg = Math.round(prep_level_sum / 5); // Average is taken of 5 instead of 6 since it's assumed to be better to overestimate the APL in weird cases.
            
            prep_level_suggestion = prep_level_avg;
        }

        // set ALL calucations

        let newCalculations = {
            a_total: ( (parseInt(formData.a_ltg) || 0) + (parseInt(formData.a_hum) || 0) + (parseInt(formData.a_oth) || 0) ).toString(),
            b_total_active: ( (parseInt(formData.b_oc) || 0) + (parseInt(formData.b_bh) || 0) + (parseInt(formData.b_uc) || 0) ).toString(),
            b_total_fires: ( (parseInt(formData.b_oc) || 0) + (parseInt(formData.b_bh) || 0) + (parseInt(formData.b_uc) || 0) + (parseInt(formData.b_total_out) || 0) ).toString(),
            b_total_area: ( (parseFloat(formData.b_active_hectares) || 0) + (parseFloat(formData.b_out_hectares) || 0) ).toString(),
            c_total: ( (parseInt(formData.c_act) || 0) + (parseInt(formData.c_out) || 0) ).toString(),
            c2_total: ( (parseInt(formData.c2_act) || 0) + (parseInt(formData.c2_out) || 0) ).toString(),
            d_total_fires: 0,
            d_total_area: 0.00,
            e_total: ( (parseInt(formData.e_act) || 0) + (parseInt(formData.e_out) || 0) ).toString(),
            prep_level_suggested: prep_level_suggestion
        }

        newCalculations.d_total_fires = ( (parseInt(newCalculations.b_total_fires) || 0) + (parseInt(newCalculations.c_total) || 0) + (parseInt(newCalculations.c2_total) || 0) ).toString();
        newCalculations.d_total_area = ( (parseFloat(newCalculations.b_total_area) || 0) + (parseFloat(formData.c_total_hectares) || 0) + (parseFloat(formData.c2_total_hectares) || 0) ).toString();

        setFormCalculated(newCalculations);

    }, [ formData ]);

    // custom form BRING FORWARD handlers

    const bringForwardPrescribed = () => {
        
        if (
            !formData.hasOwnProperty('previous')
            || !formData.previous.hasOwnProperty('e_act')
            || !formData.previous.hasOwnProperty('e_out')
            || !formData.previous.hasOwnProperty('e_total_hectares')
        ) {
            window.alert( t('There is no data to bring forward.') );
        } else {
            const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );

            if ( isConfirmed ) {
                setFormData({
                    ...formData,
                    e_act: formData.previous.e_act,
                    e_out: formData.previous.e_out,
                    e_total_hectares: formData.previous.e_total_hectares
                });

                handleBlur(null);
            }
        }
    }

    const bringForwardPriority = () => {
        if (
            !formData.hasOwnProperty('previous')
            || !formData.previous.hasOwnProperty('priority_fires')
            || !formData.previous.priority_fires
            || !formData.previous.priority_fires.length
        ) {
            window.alert( t('There is no data to bring forward.') );
        } else {
            const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );

            if ( isConfirmed ) {
                setFormData({
                    ...formData,
                    priority_fires: formData.previous.priority_fires
                });

                handleBlur(null);
            }
        }
    }

    const bringForwardMobs = () => {
        if (
            !formData.hasOwnProperty('previous')
            || !formData.previous.hasOwnProperty('mobs')
            || !formData.previous.mobs
            || !formData.previous.mobs.length
        ) {
            window.alert( t('There is no data to bring forward.') );
        } else {
            const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );

            if ( isConfirmed ) {
                setFormData({
                    ...formData,
                    mobs: formData.previous.mobs
                });

                handleBlur(null);
            }
        }
    }

    // format SUBMIT buttons and associated HANDLERs

    const handleSubmit = async ( submitType ) => {
        if (
            isLoggedIn
            && isEditing
        ) {

            // submit the CURRENT form data for validation, and only IF PASSES will we allow submit byt PASSING IN the type (this should be the ONLY WAY to change triggerSubmit state to anything but FALSE)
            handleSync( submitType );

        }
    }  

    const [triggerSubmit, setTriggerSubmit] = useState(false);
    
    useEffect(() => {
        if ( triggerSubmit ) {
            
            console.log('UE triggerSubmit');

            if (
                !Object.keys(formStatus.validationErrors).length
                && !formStatus.isLocked
            ) {

                const isConfirmed = window.confirm( t('Are you sure you want to submit this data for approval?') );

                if ( isConfirmed ) {

                    // update FORM STATUS to display syncing is STARTING...
                    setFormStatus({
                        ...formStatus, 
                        isSyncing: true, 
                        displayStatus: false,
                    });

                    // figure out TYPE of form submit, use correct URL

                    let submitUrl = ApiEndpoints.dipSitrepPostSubmit();

                    switch ( triggerSubmit ) {
                        case 'approve':
                            submitUrl = ApiEndpoints.dipSitrepPostApprove();
                            break;
                        case 'reject':
                            submitUrl = ApiEndpoints.dipSitrepPostReject();
                            break;
                        default:
                            submitUrl = ApiEndpoints.dipSitrepPostSubmit();
                    }

                    axios
                        .post(
                            submitUrl,
                            { agency: agencyCode },
                            {
                                headers: {
                                    Authorization: "Bearer " + activeUser.jwt
                                }
                            }
                        )
                        .then(({ data }) => {
                            
                            console.log('SUBMITTED successfully', data);

                            // figure out default status, and then determine DISPLAYABLE status from constant file
                            let displayStatus = 'N';
                            if ( data.status.trim() ) displayStatus = data.status.toUpperCase();
                            displayStatus = FormStatuses[displayStatus].title;
    
                            let newFormStatus = {
                                isSyncing: false,
                                isLocked: data.locked,
                                isError: false,
                                lastEditedBy: data.edited_by,
                                lastClaimedBy: data.claimed_by,
                                displayLastUpdated: data.last_edit,
                                displayStatus: displayStatus,
                                validationErrors: data.validationErrors || {}
                            }
    
                            // update FORM STATUS with new form STATUS on success
                            setFormStatus({
                                ...formStatus,
                                ...newFormStatus
                            });

                            // ACKNOWLEDGE sync to server and REDIRECT away from form
                            window.alert( t('Data synced with server.')+' '+t('The action has been successfully saved.') );
                            history.push("/agency");

                        })
                        .catch((error) => {
                            console.log("SUBMITTED, with error", error);

                            let newFormStatus = {
                                isSyncing: false,
                                isError: true,
                                validationErrors: error.response.data.validationErrors
                            }

                            if ( error.response.data.hasOwnProperty('locked') ) { newFormStatus.isLocked = error.response.data.locked }
                            if ( error.response.data.hasOwnProperty('last_edit') ) { newFormStatus.displayLastUpdated = error.response.data.last_edit }
                            if ( error.response.data.hasOwnProperty('edited_by') ) { newFormStatus.lastEditedBy = error.response.data.edited_by }

                            if ( error.response.data.hasOwnProperty('status') ) { 
                                // figure out default status, and then determine DISPLAYABLE status from constant file
                                newFormStatus.displayStatus = 'N';
                                if ( error.response.data.status.trim() ) newFormStatus.displayStatus = error.response.data.status.toUpperCase();
                                newFormStatus.displayStatus = FormStatuses[newFormStatus.displayStatus].title;
                            }
                            
                            newFormStatus = {
                                ...formStatus,
                                ...newFormStatus
                            }
                            
                            // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        });

                } else {
                    console.log('SUBMITTED cancelled by confirm()');
                }

            }

            // ALWAYS set this back to FALSE afterwards
            setTriggerSubmit(false);

        }
    }, [ triggerSubmit ]);

    // format CLAIM BUTTON handler...

    const handleClaim = async ( setEditTo=true ) => {
        
        if (
            isLoggedIn
            && getSitrepType!=='approved'
            && getSitrepType!=='published'
        ) {

            console.log('handleClaim() CALLED');

            // if trying to CLAIM the sitrep...
            if (
                setEditTo
                // ...and is ALLOWED to claim this sitrep...
                && (
                    !parseInt(formStatus.lastClaimedBy)
                    || parseInt(formStatus.lastClaimedBy) === parseInt(activeUser.user.id)
                )
            ) {

                const urlClaim = ApiEndpoints.dipSitrepPostClaim() + '?agency=' + agencyCode;
                const payloadClaim = {};

                axios
                    .post(
                        urlClaim,
                        payloadClaim,
                        {
                            headers: {
                                'Authorization': "Bearer " + activeUser.jwt,
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then(({ data }) => {
                        console.log( 'CLAIM, true, got', data);

                        // ...and if SUCCESSFUL, switch IS-EDIT to claim MODE
                        setIsEditing( true );
                    })
                    .catch((error) => {
                        console.log( 'CLAIM, true, error', error);
                        
                        // ...and if SUCCESSFUL, switch IS-EDIT to claim MODE
                        setIsEditing( false );
                    });

            }

            // if trying to RELEASE the sitrep...
            else {

                const urlClaimRelease = ApiEndpoints.dipSitrepPostRelease() + '?agency=' + agencyCode;
                const payloadClaimRelease = {};

                axios
                    .post(
                        urlClaimRelease,
                        payloadClaimRelease,
                        {
                            headers: {
                                'Authorization': "Bearer " + activeUser.jwt,
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then(({ data }) => {
                        console.log( 'CLAIM, false, got', data);

                        // ...and if SUCCESSFUL, switch IS-EDIT to claim MODE
                        setIsEditing( false );
                    })
                    .catch((error) => {
                        console.log( 'CLAIM, false, error', error);
                        
                        // ...and if SUCCESSFUL, switch IS-EDIT to claim MODE
                        setIsEditing( false );
                    });

            }

        }

    } 

    // render CLAIM BUTTONS, based on type of sitrep (DRAFT, APPROVED, PUBLISHED), if its been CLAIMED, and who has CLAIMED it...
    
    let claimButton = '';
    let claimInstructions = null;

    if (
        getSitrepType==='approved'
        || getSitrepType==='published'
    ) {
        claimInstructions = t('You are currently viewing a static version of this sitrep. This version was previous submitted and approved by CIFFC. This version can no longer be edited. If you wish to replace this version, you will need to start or edit a new draft version.');
    } else if (
        formStatus.isSyncing
        || doSync
    ) {
        // if user IS EDITING the current sitrep, the only option is to UNCLAIM the sitrep...
        claimButton = <>
            <button type="button" id="claimSitrepButton" className="button button-edit inline" style={{pointerEvents:"none", opacity:0.2}}>
                <FontAwesomeIcon icon={ solid('clock') } />
                { formStatus.isSyncing ? t('Syncing') : t('Changes are queued to be synced') }
            </button>
        </>;

        claimInstructions = t('You are currently editing this sitrep. While you are doing so, no other user will be allowed to edit nor submit it. It will be exclusively yours to edit until you either pause editing, submit the form, or the site determines you have abandoned it.');
    } else if (
        isEditing
    ) {
        // if user IS EDITING the current sitrep, the only option is to UNCLAIM the sitrep...
        claimButton = <>
            <button type="button" id="claimSitrepButton" className="button button-edit inline" onClick={ ()=>handleClaim(false) }>
                <FontAwesomeIcon icon={ solid('pause') } />
                { t('Pause Edit') }&hellip;
            </button>
        </>;

        claimInstructions = t('You are currently editing this sitrep. While you are doing so, no other user will be allowed to edit nor submit it. It will be exclusively yours to edit until you either pause editing, submit the form, or the site determines you have abandoned it.');
    } else if (
        parseInt(formStatus.lastClaimedBy)>0
        && parseInt(formStatus.lastClaimedBy) !== parseInt(activeUser.user.id)
    ) {
        // this sitrep CANNOT be claimed...
        claimButton = <>
            <button type="button" id="claimSitrepButton" className="button button-dark inline" disabled="disabled">
                <FontAwesomeIcon icon={ solid('lock') } />
                { t('Edit Locked') }
            </button>
        </>;

        claimInstructions = t('You are currently viewing this sitrep. While viewing, the sitrep is static and you will not make any changes to it. This sitrep is locked in its current state and you are not allowed to make any edits to it.');
    } else {
        // allow the current user to CLAIM the sitrep (aka, "locked" aka "sign out")
        claimButton = <>
            <button type="button" id="claimSitrepButton" className="button button-edit inline" onClick={ ()=>handleClaim() }>
                <FontAwesomeIcon icon={ solid('play') } />
                { t('Start Editing') }&hellip;
            </button>
        </>;

        claimInstructions = t('You are currently viewing this sitrep. While viewing, the sitrep is static and you will not make any changes to it. You can start editing this sitrep at any time, so long as it is not being edited by another user.');
    }

    // render SUBMIT BUTTONS, based on editing-view mode, form status, and which user is logged in, etc

    let submitButtons = '';

    if ( !isEditing ) {
        // no submit buttons when in view-only mode
    } else if (
        // if form is currently SYNCING with server...
        formStatus.isSyncing
        // or if form is queued TO SYNC with server...
        || doSync
    ) {
        
        submitButtons = <>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                    <FontAwesomeIcon icon={ solid('rotate') } />
                        { formStatus.isSyncing ? t('Syncing') : t('Changes are queued to be synced') }
                    </button>
                </div>
            </>

    } else if (
        // if the form is LOCKED (and therefore cannot be submitted)...
        formStatus.isLocked
    ) {
        
        submitButtons = <>
                <div className="input" data-validation={ t('This data is locked and currently cannot be edited.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else if (
        // if the form has VALIDATION ERROR(s) that are preventing it from being submitted to the server...
        Object.keys(formStatus.validationErrors).length
    ) {
        
        submitButtons = 
            <>
                <div className="input" data-validation={ t('This data has one or more validation errors; please review it.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else if (
        // ...otherwise, if AGENCY USER is logged in ....
        !isCiffc
    ) {

        // UNBLOCK button only if sitrep in submittable state, else default to blocked button...

        if (

            // not yet submitted at all
            formStatus.displayStatus === FormStatuses.N.title

            // previously submitted but agency is allowed to change after the fact
            || formStatus.displayStatus === FormStatuses.S.title
            || formStatus.displayStatus === FormStatuses.R.title
            || formStatus.displayStatus === FormStatuses.A.title
            
            // or the user is the one editing it (only way it cannot be locked)
            || (
            	formStatus.displayStatus === FormStatuses.E.title
            	&& !formStatus.isLocked
            )


        ){

            submitButtons = <div className="form-submit-buttons">
                    <button type="button" className={ "button-submit " + FormStatuses.S.className } onClick={ () => handleSubmit('submit') }>
                        <FontAwesomeIcon icon={ solid('clipboard') } />
                        { t('Submit') }
                    </button>
                </div>
        }

        // ...if in NOT-ALLOWED-TO-SUBMIT state, prevent button from being able to submit

        else {
            
            submitButtons = <>
                    <div className="input form-submit-buttons">
                        <button type="button" disabled="disabled" className="button-submit">
                            <FontAwesomeIcon icon={ solid('clipboard') } />
                            { t('Submit') }
                        </button>
                    </div>
                </>

        }

    } 
    
    // ...or if it is CIFFC user logged in, they get APPROVE or REJECT buttons at this point

    else {

        let approveButtonDisabled = false;
        if ( formStatus.displayStatus === FormStatuses.A.title ) { approveButtonDisabled = true; }

        let rejectButtonDisabled = false;
        if ( formStatus.displayStatus === FormStatuses.R.title ) { rejectButtonDisabled = true; }

        submitButtons = <div className="form-submit-buttons">
                <button type="button" disabled={approveButtonDisabled} className={ "button-submit " + FormStatuses.A.className } onClick={ () => handleSubmit('approve') }>
                    <FontAwesomeIcon icon={ solid('square-check') } />
                    { t('Approved') }
                </button>

                <button type="button" disabled={rejectButtonDisabled} className={ "button-submit " + FormStatuses.R.className } onClick={ () => handleSubmit('reject') }>
                    <FontAwesomeIcon icon={ solid('square-xmark') } />
                    { t('Not Approved') }
                </button>
            </div>

    }

    // format display FORM TITLE

    let titleFormatted =  t('You are @mode') + ': ' + t('CIFFC SitRep Input for @agency for @date');
    titleFormatted = isEditing ? titleFormatted.replace( '@mode', t('Editing') ) : titleFormatted.replace( '@mode', t('Viewing') );
    titleFormatted = titleFormatted.replace( '@date', moment().format('ll') );
    titleFormatted = titleFormatted.replace( '@agency', ( agencyCode ? agencyCode.toUpperCase() : '(' + t('Undefined') + ')' ) );
    if ( getSitrepType==='approved' ) titleFormatted += ' ('+t('Approved')+')'
    if ( getSitrepType==='published' ) titleFormatted += ' ('+t('Published')+')'
    
    // format display of sitrep STATUS...

    let statusFormatted = '-';
    let statusFormattedBy = '';

    if (
        formStatus.isSyncing
        // || doSync
    ) {
        statusFormatted = <em className={ FormStatuses.SYNCING.className }>{ formStatus.isSyncing }</em>
        // statusFormatted = <em className={ FormStatuses.SYNCING.className }>{ formStatus.isSyncing ? t('Syncing') : t('Changes are queued to be synced') }</em>
    } else if (
        formStatus.displayStatus===FormStatuses.E.title
        && parseInt(formData["edited_by"]) // a NEW form will not have an editted-by value (zero), so we should not count that as an other-user match
        && formStatus["lastEditedBy"].toString() !== activeUser.user.id.toString()
    ) {
        statusFormattedBy = t('By @name').toLowerCase();
        statusFormattedBy = statusFormattedBy.replace( '@name', formStatus["lastEditedBy"].toString() );
        statusFormatted = <em className={ FormStatuses.E.className }>{ t('Being Edited') }, { statusFormattedBy }</em>
    } else if (
        formStatus.isError
    ) {
         statusFormatted = <em className={ FormStatuses.ERROR.className }>{ t(FormStatuses.ERROR.title) }</em>
    } else if (
        formStatus.displayStatus===FormStatuses.E.title
        && parseInt(formData["edited_by"]) // a NEW form will not have an editted-by value (zero), so we should not count that as an other-user match
        && formStatus["lastEditedBy"].toString() === activeUser.user.id.toString()
    ) {
        statusFormattedBy = t('by you');
        statusFormatted = <em className={ FormStatuses.E.className }>{ t('Being Edited') }, { statusFormattedBy }</em>
    } else if (
        formStatus.displayStatus
    ) {
        statusFormatted = ( formStatus.displayStatus===FormStatuses.E.title ? <em className={ FormStatuses.E.className }>{ t(formStatus.displayStatus) }</em> : t(formStatus.displayStatus) );
    }
        
    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( agencyCode ? t('Agency SitRep Input')+' '+agencyCode.toUpperCase() : false );

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t('Agency SitRep Input') */ }
                <StaticContent staticContentAlias="sitrep-input-agency" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <h3 className={ "form-sitrep-title" + ( isEditing ? ' is-editing' : '' ) }>{ titleFormatted }</h3>

                <form className={ 'form-sitrep form-sitrep-type-'+getSitrepType + ( isEditing ? ' is-editing' : ' is-locked' ) }> 
                    
                    <div className="form-status-wrapper">
                        
                        <dl className="form-status">
                            <dt>{ t('SitRep') }</dt>
                            <dd>{ agencyCode ? agencyCode.toUpperCase() : '-' }</dd>
                        
                            <dt>{ t('Today') }</dt>
                            <dd>{ moment().format('ll') }</dd>
                        </dl>

                        {
                        /*
                        <dl className="form-status">

                            <dt>{ t('Username') }</dt>
                            <dd>{ activeUser.user !== undefined ? activeUser.user.username : null }</dd>
                            
                            <dt>{ t('Language') }</dt>
                            <dd>{ activeUser.user !== undefined ? i18n.language : null }</dd>
                        </dl> 
                        */
                        }

                        <dl className="form-status">
                            <dt>{ t('Status') }</dt>
                            <dd>{ statusFormatted }</dd>

                            <dt>{ t('Version') }</dt>
                            <dd>{ !formStatus.versionNumber ? '-' : formStatus.versionNumber+'' }</dd>
                        
                            <dt>{ t('Last update') }</dt>
                            <dd>
                                { !formStatus.isSyncing 
                                    ? (
                                        formStatus.displayLastUpdated 
                                            ? moment( formStatus.displayLastUpdated ).tz( moment.tz.guess() ).format('llll z') // purposely including TIME with displayed sync-ed data
                                            : '-'
                                        )
                                    : <em className={ FormStatuses.SYNCING.className }>{ t('Syncing') }</em>
                                }
                            </dd>
                        </dl>

                        { claimInstructions!==null ? <p id="claimSitrepInstructions">{claimInstructions}</p> : null }
                        
                        { claimButton }

                    </div>
                    
                    <fieldset className="form-wrapper" disabled={ formStatus.isLocked ? 'disabled' : false }>

                        <div className="input" data-validation={ t(formStatus.validationErrors.form_0) }></div>
                        
                        <h4>{ t('Fire Situation') }</h4>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_1) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Agency Situation and Preparedness Summary') }</legend>

                                <p>{ t('A text field where you can provide a brief description of your agency’s current fire situation. Please do not enter the number of new fires or their status as that data is already captured in the tables.') }</p>

                                    {
                                        isCiffc

                                        ? <>
                                                <div className="input" data-validation={ t(formStatus.validationErrors.en_ops_summary) }>
                                                    <em className="label-info">English</em>
                                                    <textarea name="en_ops_summary" onChange={handleChange} onBlur={handleBlur} value={formData["en_ops_summary"]} />
                                                </div>
                                                <div className="input" data-validation={ t(formStatus.validationErrors.fr_ops_summary) }>
                                                    <em className="label-info">Français</em>
                                                    <textarea name="fr_ops_summary" onChange={handleChange} onBlur={handleBlur} value={formData["fr_ops_summary"]} />
                                                </div>
                                            </>

                                        : <div className="input" data-validation={ t(formStatus.validationErrors[i18n.language + '_ops_summary']) }>
                                                <textarea name={i18n.language + '_ops_summary'} onChange={handleChange} onBlur={handleBlur} value={formData[i18n.language + '_ops_summary']} />
                                            </div>
                                    }
                            </fieldset>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_2) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Fire Statistics') }</legend>

                                <p>{ t('For current year from January 1.') }</p>

                                <fieldset className="input" disabled={ formStatus.isDipFed ? 'disabled' : false }>
                                    <legend>{ t('New Wildland Fires Yesterday') }</legend>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.a_ltg) }>
                                        <label>{ t('Natural') }</label>
                                        <input type="number" name="a_ltg" onChange={handleChange} onBlur={handleBlur} value={formData.a_ltg} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.a_hum) }>
                                        <label>{ t('Human') }</label>
                                        <input type="number" name="a_hum" onChange={handleChange} onBlur={handleBlur} value={formData.a_hum} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.a_oth) }>
                                        <label>{ t('Undetermined') }</label>
                                        <input type="number" name="a_oth" onChange={handleChange} onBlur={handleBlur} value={formData.a_oth} min="0" />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Total') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.a_total} />
                                    </div>
                                </fieldset>

                                <fieldset className="input" disabled={ formStatus.isDipFed ? 'disabled' : false }>
                                    <legend>{ t('Full Response') }</legend>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_oc) }>
                                        <label>{ t('OC') }</label>
                                        <input type="number" name="b_oc" onChange={handleChange} onBlur={handleBlur} value={formData.b_oc} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_bh) }>
                                        <label>{ t('BH') }</label>
                                        <input type="number" name="b_bh" onChange={handleChange} onBlur={handleBlur} value={formData.b_bh} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_uc) }>
                                        <label>{ t('UC') }</label>
                                        <input type="number" name="b_uc" onChange={handleChange} onBlur={handleBlur} value={formData.b_uc} min="0" />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Active') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.b_total_active} />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_active_hectares) }>
                                        <label>{ t('Active Hectares') }</label>
                                        <input type="number" name="b_active_hectares" onChange={handleChange} onBlur={handleBlur} value={formData.b_active_hectares} min="0" step="any" />
                                    </div>

                                    <span className="spacer"></span>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_total_out) }>
                                        <label>{ t('Out') }</label>
                                        <input type="number" name="b_total_out" onChange={handleChange} onBlur={handleBlur} value={formData.b_total_out} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.b_out_hectares) }>
                                        <label>{ t('Out Hectares') }</label>
                                        <input type="number" name="b_out_hectares" onChange={handleChange} onBlur={handleBlur} value={formData.b_out_hectares} min="0" step="any" />
                                    </div>

                                    <span className="spacer"></span>

                                    <div className="input">
                                        <label>{ t('Total') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.b_total_fires} />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Total Hectares') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.b_total_area} />
                                    </div>
                                </fieldset>

                                <fieldset className="input" disabled={ formStatus.isDipFed ? 'disabled' : false }>
                                    <legend>{ t('Modified Response') }</legend>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c_act) }>
                                        <label>{ t('Active') }</label>
                                        <input type="number" name="c_act" onChange={handleChange} onBlur={handleBlur} value={formData.c_act} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c_out) }>
                                        <label>{ t('Out') }</label>
                                        <input type="number" name="c_out" onChange={handleChange} onBlur={handleBlur} value={formData.c_out} min="0" />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Total') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.c_total} />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c_total_hectares) }>
                                        <label>{ t('Hectares') }</label>
                                        <input type="number" name="c_total_hectares" onChange={handleChange} onBlur={handleBlur} value={formData.c_total_hectares} min="0" step="any" />
                                    </div>
                                </fieldset>

                                <fieldset className="input" disabled={ formStatus.isDipFed ? 'disabled' : false }>
                                    <legend>{ t('Monitored Response') }</legend>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c2_act) }>
                                        <label>{ t('Active') }</label>
                                        <input type="number" name="c2_act" onChange={handleChange} onBlur={handleBlur} value={formData.c2_act} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c2_out) }>
                                        <label>{ t('Out') }</label>
                                        <input type="number" name="c2_out" onChange={handleChange} onBlur={handleBlur} value={formData.c2_out} min="0" />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Total') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.c2_total} />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.c2_total_hectares) }>
                                        <label>{ t('Hectares') }</label>
                                        <input type="number" name="c2_total_hectares" onChange={handleChange} onBlur={handleBlur} value={formData.c2_total_hectares} min="0" step="any" />
                                    </div>
                                </fieldset>

                                <fieldset className="input" disabled={ formStatus.isDipFed ? 'disabled' : false }>
                                    <legend>{ t('Totals') }</legend>

                                    <div className="input">
                                        <label>{ t('Fires') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.d_total_fires} />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Hectares') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.d_total_area} />
                                    </div>
                                </fieldset>

                                <fieldset className="input">
                                    <legend>{ t('Prescribed Fires') }</legend>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.e_act) }>
                                        <label>{ t('Active') }</label>
                                        <input type="number" name="e_act" onChange={handleChange} onBlur={handleBlur} value={formData.e_act} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.e_out) }>
                                        <label>{ t('Out') }</label>
                                        <input type="number" name="e_out" onChange={handleChange} onBlur={handleBlur} value={formData.e_out} min="0" />
                                    </div>

                                    <div className="input">
                                        <label>{ t('Total') }</label>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.e_total} />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.e_total_hectares) }>
                                        <label>{ t('Hectares') }</label>
                                        <input type="number" name="e_total_hectares" onChange={handleChange} onBlur={handleBlur} value={formData.e_total_hectares} min="0" step="any" />
                                    </div>
                                </fieldset>
                                
                                {
                                    // button only needed in editing mode...
                                    isEditing ?
                                        <button type="button" className="link button-link" onClick={ bringForwardPrescribed }>
                                            <FontAwesomeIcon icon={ solid('file-import') } />
                                            { t('Bring Forward Prescribed Fires') }
                                        </button>
                                        : null // ... can remain hidden in VIEW mode
                                }

                            </fieldset>

                            <div className="input" data-validation={  t(formStatus.validationErrors.form_3) }></div>

                            <fieldset className="form-section">
                                <legend>
                                    { t('Priority Wildland Fires') }

                                    {
                                        // button only needed in editing mode...
                                        isEditing ?
                                            <button type="button" className="link button-link" onClick={ bringForwardPriority }>
                                                <FontAwesomeIcon icon={ solid('file-import') } />
                                                { t('Bring Forward Priority Fires') }
                                            </button>
                                            : null // ... can remain hidden in VIEW mode
                                    }
                                </legend>

                                <div className="input-series">
                                    {
                                        formData.priority_fires.map( (item, index) => (
                                            <div className="input-series-set" key={item.seriesKey}>
                                                
                                                <fieldset className="input">
                                                    <legend>{ t('Fire') }</legend>
                                                    <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].fires) : null }>
                                                        <input type="text" name={"priority_fires["+index+"].fires"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].fires } list="datalist_options_priority_fires" />
                                                    </div>

                                                    <span className="spacer"></span>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].latitude) : null }>
                                                        <label>{ t('Latitude') }</label>
                                                        <input type="text" name={"priority_fires["+index+"].latitude"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].latitude } />
                                                    </div>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ? t(formStatus.validationErrors.priority_fires[index].longitude) : null }>
                                                        <label>{ t('Longitude') }</label>
                                                        <input type="text" name={"priority_fires["+index+"].longitude"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].longitude } />
                                                    </div>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].stage_of_control) : null }>
                                                        <label>{ t('Stage of Control') }</label>
                                                        <select name={"priority_fires["+index+"].stage_of_control"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].stage_of_control }>
                                                            <option></option>
                                                            <option value="OUT">{ t('Out') }</option>
                                                            <option value="UC">{ t('Under control') }</option>
                                                            <option value="BH">{ t('Being Held') }</option>
                                                            <option value="OC">{ t('Out of control') }</option>
                                                        </select>
                                                    </div>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].size) : null }>
                                                        <label>{ t('Size') }</label>
                                                        <input type="text" name={"priority_fires["+index+"].size"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].size } min="0" />
                                                    </div>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].incident_type) : null }>
                                                        <label>{ t('Incident Type') }</label>
                                                        <select name={"priority_fires["+index+"].incident_type"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].incident_type }>
                                                            <option></option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </select>
                                                    </div>
                                                    
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ? formStatus.validationErrors.priority_fires[index].percent_contained : null }>
                                                        <label>{ t('% Contained') }</label>
                                                        <input type="number" name={"priority_fires["+index+"].percent_contained"} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index].percent_contained } min="0" max="100" />
                                                    </div>

                                                    <span className="spacer"></span>
                                                    
                                                    {
                                                        /*

                                                        // COMMENTS for section-c has been disabled/removed, at client request

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('priority_fires') && formStatus.validationErrors.priority_fires.hasOwnProperty(index) ?  t(formStatus.validationErrors.priority_fires[index].comments_en) : null }>
                                                            <label>{ t('Comments') }</label>
                                                            
                                                            {
                                                                isCiffc

                                                                ? <>
                                                                        <em className="label-info">English</em>
                                                                        <textarea name={"priority_fires["+index+"].comments_en"} onChange={handleChange} onBlur={handleBlur} value={formData.priority_fires[index].comments_en} />
                                                                        <em className="label-info">Français</em>
                                                                        <textarea name={"priority_fires["+index+"].comments_fr"} onChange={handleChange} onBlur={handleBlur} value={formData.priority_fires[index].comments_fr} />
                                                                    </>

                                                                : <>
                                                                        <textarea name={"priority_fires["+index+"].comments_"+i18n.language} onChange={handleChange} onBlur={handleBlur} value={ formData.priority_fires[index]['comments_'+i18n.language] } />
                                                                    </>
                                                            }


                                                        </div>
                                                        */
                                                    }

                                                </fieldset>

                                                <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("priority_fires", item.seriesKey) }>
                                                    <FontAwesomeIcon icon={ solid('delete-left') } />
                                                    <span className="button-label">{ t('Remove') }</span>
                                                </button>

                                            </div>
                                        ))
                                    }
                                </div>
                                
                                {
                                    // button only needed in editing mode...
                                    isEditing ?
                                        <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("priority_fires", initialDataPriorityFires) }>
                                            <FontAwesomeIcon icon={ solid('list') } />
                                            { t('Add another item') }
                                        </button>
                                        : null // ... can remain hidden in VIEW mode
                                }

                                <datalist id="datalist_options_priority_fires">
                                    {
                                        Object.keys(priorityFiresData).map(function(fireId, index) {
                                            return <option key={index} value={fireId} />
                                        })
                                    }
                                </datalist>

                            </fieldset>
                        
                        <h4>{ t('Planning') }</h4>
                            
                            <div className="input" data-validation={ t(formStatus.validationErrors.form_4) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Fire Occurence Predition for Tomorrow') }</legend>

                                <fieldset className="input" data-validation={ t(formStatus.validationErrors.fop_ltg) }>
                                    <div className="input">
                                        <label>{ t('Natural') }</label>
                                        <input type="text" name="fop_ltg" onChange={handleChange} onBlur={handleBlur} value={formData.fop_ltg} />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.fop_nat) }>
                                        <label>{ t('Human') }</label>
                                        <input type="text" name="fop_nat" onChange={handleChange} onBlur={handleBlur} value={formData.fop_nat} />
                                    </div>
                                </fieldset>
                            </fieldset>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_5) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Agency Prepardness Level') }</legend>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.current_fire_hazard) }>
                                    <legend>{ t('Our agency fire danger is:') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="current_fire_hazard" onChange={handleChange} checked={formData.current_fire_hazard.toString()==='1'} value="1" />
                                        <label>{ t('Low')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_hazard" onChange={handleChange} checked={formData.current_fire_hazard.toString()==='2'} value="2" />
                                        <label>{ t('Moderate')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_hazard" onChange={handleChange} checked={formData.current_fire_hazard.toString()==='3'} value="3" />
                                        <label>{ t('High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_hazard" onChange={handleChange} checked={formData.current_fire_hazard.toString()==='4'} value="4" />
                                        <label>{ t('Very High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_hazard" onChange={handleChange} checked={formData.current_fire_hazard.toString()==='5'} value="5" />
                                        <label>{ t('Extreme')}</label>
                                    </div>
                                </fieldset>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.current_fire_load) }>
                                    <legend>{ t('Our current fire load is:') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="current_fire_load" onChange={handleChange} checked={formData.current_fire_load.toString()==='1'} value="1" />
                                        <label>{ t('Low')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_load" onChange={handleChange} checked={formData.current_fire_load.toString()==='2'} value="2" />
                                        <label>{ t('Moderate')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_load" onChange={handleChange} checked={formData.current_fire_load.toString()==='3'} value="3" />
                                        <label>{ t('High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_load" onChange={handleChange} checked={formData.current_fire_load.toString()==='4'} value="4" />
                                        <label>{ t('Very High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="current_fire_load" onChange={handleChange} checked={formData.current_fire_load.toString()==='5'} value="5" />
                                        <label>{ t('Extreme')}</label>
                                    </div>
                                </fieldset>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.expected_fire_load) }>
                                    <legend>{ t('Our anticipated fire load (over the next 7 days) is:') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="expected_fire_load" onChange={handleChange} checked={formData.expected_fire_load.toString()==='1'} value="1" />
                                        <label>{ t('Low')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="expected_fire_load" onChange={handleChange} checked={formData.expected_fire_load.toString()==='2'} value="2" />
                                        <label>{ t('Moderate')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="expected_fire_load" onChange={handleChange} checked={formData.expected_fire_load.toString()==='3'} value="3" />
                                        <label>{ t('High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="expected_fire_load" onChange={handleChange} checked={formData.expected_fire_load.toString()==='4'} value="4" />
                                        <label>{ t('Very High')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="expected_fire_load" onChange={handleChange} checked={formData.expected_fire_load.toString()==='5'} value="5" />
                                        <label>{ t('Extreme')}</label>
                                    </div>
                                </fieldset>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.agency_resource_levels) }>
                                    <legend>{ t('Our agency resource levels are:') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="agency_resource_levels" onChange={handleChange} checked={formData.agency_resource_levels.toString()==='1'} value="1" />
                                        <label>{ t('Adequate')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="agency_resource_levels" onChange={handleChange} checked={formData.agency_resource_levels.toString()==='2'} value="2" />
                                        <label>{ t('Some Assistance')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="agency_resource_levels" onChange={handleChange} checked={formData.agency_resource_levels.toString()==='3'} value="3" />
                                        <label>{ t('Assistance Required')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="agency_resource_levels" onChange={handleChange} checked={formData.agency_resource_levels.toString()==='4'} value="4" />
                                        <label>{ t('Inadequate')}</label>
                                    </div>
                                </fieldset>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.ciffc_response_capacity) }>
                                    <legend>{ t("Our agency's ability to respond to CIFFC resource requests is:") }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="ciffc_response_capacity" onChange={handleChange} checked={formData.ciffc_response_capacity.toString()==='1'} value="1" />
                                        <label>{ t('Excellent')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="ciffc_response_capacity" onChange={handleChange} checked={formData.ciffc_response_capacity.toString()==='2'} value="2" />
                                        <label>{ t('Good')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="ciffc_response_capacity" onChange={handleChange} checked={formData.ciffc_response_capacity.toString()==='3'} value="3" />
                                        <label>{ t('Moderate')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="ciffc_response_capacity" onChange={handleChange} checked={formData.ciffc_response_capacity.toString()==='4'} value="4" />
                                        <label>{ t('Poor')}</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="ciffc_response_capacity" onChange={handleChange} checked={formData.ciffc_response_capacity.toString()==='5'} value="5" />
                                        <label>{ t('No Ability')}</label>
                                    </div>
                                </fieldset>
                                
                                {

                                    /*

                                    // POTENTIAL INTERNTATIONAL ASSISTANCE for section-e has been disabled/removed, at client request

                                    <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.international) }>
                                        <legend>{ t('Our potential for international assistance is:') }</legend>
                                        
                                        <div className="input">
                                            <input type="radio" name="international" value="1" onChange={handleChange} checked={formData.international.toString()==='1'} />
                                            <label>{ t('Nil')}</label>
                                        </div>

                                        <div className="input">
                                            <input type="radio" name="international" value="2" onChange={handleChange} checked={formData.international.toString()==='2'} />
                                            <label>{ t('Increasing')}</label>
                                        </div>

                                        <div className="input">
                                            <input type="radio" name="international" value="3" onChange={handleChange} checked={formData.international.toString()==='3'} />
                                            <label>{ t('Consideration')}</label>
                                        </div>
                                    </fieldset>

                                    */

                                }

                                <fieldset className="input">
                                    <div className="input" data-validation={ t(formStatus.validationErrors.prep_level_override) }>
                                        <label>{ t('Preparedness Level') }</label>
                                        <select name="prep_level_override" onChange={handleChange} onBlur={handleBlur} value={formData.prep_level_override}>
                                            <option value="0"></option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>

                                    <div className="input wide">
                                        <em className="label-info">{ t('Suggested Preparedness Level based on your selections above:') }</em>
                                        <input type="text" readOnly="readonly" disabled="disabled" value={formCalculated.prep_level_suggested} />
                                    </div>
                                </fieldset>

                                {
                                    /*

                                    // COMMENTS for section-e has been disabled/removed, at client request

                                    <div className="input">
                                        <label>{ t('Preparedness Comments') }</label>
                                        
                                        {
                                            isCiffc

                                            ? <>
                                                    <div className="input" data-validation={ t(formStatus.validationErrors.en_prep_comment) }>
                                                        <em className="label-info">English</em>
                                                        <textarea name="en_prep_comment" onChange={handleChange} onBlur={handleBlur} value={formData.en_prep_comment} />
                                                    </div>
                                                    <div className="input" data-validation={ t(formStatus.validationErrors.fr_prep_comment) }>
                                                        <em className="label-info">Français</em>
                                                        <textarea name="fr_prep_comment" onChange={handleChange} onBlur={handleBlur} value={formData.fr_prep_comment} />
                                                    </div>
                                                </>

                                            : <div className="input" data-validation={formStatus.validationErrors[i18n.language + '_prep_comment']}>
                                                    <textarea name={i18n.language + '_prep_comment'} onChange={handleChange} onBlur={handleBlur} value={formData[i18n.language + '_prep_comment']} />
                                                </div>
                                        }
                                    </div>
                                    
                                    */
                                }
                            </fieldset>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_6) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Possibility of Resource Requirements') }</legend>

                                <p>
                                    { t('A text field where you can enter any potential resource requirements for the next 1-2 days. This is to provide CIFFC advanced notice of a possible upcoming resource order.') }
                                </p>

                                <div className="input">
                                    {
                                        isCiffc

                                        ? <>
                                                <div className="input" data-validation={ t(formStatus.validationErrors.en_porr_text) }>
                                                    <em className="label-info">English</em>
                                                    <textarea name="en_porr_text" onChange={handleChange} onBlur={handleBlur} value={formData.en_porr_text} />
                                                </div>
                                                <div className="input" data-validation={ t(formStatus.validationErrors.fr_porr_text) }>
                                                    <em className="label-info">Français</em>
                                                    <textarea name="fr_porr_text" onChange={handleChange} onBlur={handleBlur} value={formData.fr_porr_text} />
                                                </div>
                                            </>

                                        : <div className="input" data-validation={ t(formStatus.validationErrors[i18n.language + '_porr_text']) }>
                                                <textarea name={i18n.language + '_porr_text'} onChange={handleChange} onBlur={handleBlur} value={formData[i18n.language + '_porr_text']} />
                                            </div>
                                    }
                                </div>
                            </fieldset>
                        
                        <h4>{ t('Resources') }</h4>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_7) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Casual Hire Helicopters') }</legend>

                                <fieldset className="input">
                                    <div className="input" data-validation={ t(formStatus.validationErrors.chh_light) }>
                                        <label>{ t('Light') }</label>
                                        <input type="number" name="chh_light" onChange={handleChange} onBlur={handleBlur} value={formData.chh_light} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.chh_int) }>
                                        <label>{ t('Intermediate') }</label>
                                        <input type="number" name="chh_int" onChange={handleChange} onBlur={handleBlur} value={formData.chh_int} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.chh_med) }>
                                        <label>{ t('Medium') }</label>
                                        <input type="number" name="chh_med" onChange={handleChange} onBlur={handleBlur} value={formData.chh_med} min="0" />
                                    </div>

                                    <div className="input" data-validation={ t(formStatus.validationErrors.chh_heavy) }>
                                        <label>{ t('Heavy') }</label>
                                        <input type="number" name="chh_heavy" onChange={handleChange} onBlur={handleBlur} value={formData.chh_heavy} min="0" />
                                    </div>
                                </fieldset>
                            </fieldset>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_8) }></div>

                            <fieldset className="form-section">
                                <legend>
                                    { t('Compact Agreement Mobilization') }
                                    
                                    {
                                    // button only needed in editing mode...
                                        isEditing ?
                                            <button type="button" className="link button-link" onClick={ bringForwardMobs }>
                                                <FontAwesomeIcon icon={ solid('file-import') } />
                                                { t('Bring Forward Compact Mobilizations') }
                                            </button>
                                            : null // ... can remain hidden in VIEW mode
                                    }
                                </legend>

                                {
                                /*
                                <p>
                                    { t('Do not enter any resources being mobilized under the MARS agreement in this section.') } { t('These resources should be entered for each day they are mobilized until they are demobilized.') }
                                </p>

                                <div className="input-series">
                                    {
                                        formData.mobs.map( (item, index) => (
                                            <div className="input-series-set" key={item.seriesKey}>
                                                
                                                <fieldset className="input">
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].to) : null }>
                                                        <label>{ t('To') }</label>
                                                        <input type="text" name={"mobs["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs[index].to } />
                                                    </div>

                                                    <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].resources) : null }>
                                                        <ResourceOptionSet inputName={"mobs["+index+"].resources"} inputValue={ formData.mobs[index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />
                                                    </div>

                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].from) : null }>
                                                        <label>{ t('From') }</label>
                                                        <input type="text" name={"mobs["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs[index].from } />
                                                    </div>
                                                </fieldset>

                                                <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("mobs", item.seriesKey) }>
                                                    <FontAwesomeIcon icon={ solid('delete-left') } />
                                                    <span className="button-label">{ t('Remove') }</span>
                                                </button>

                                            </div>
                                        ))
                                    }
                                </div>

                                <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("mobs", initialDataMobs) }>
                                    <FontAwesomeIcon icon={ solid('list') } />
                                    { t('Add another item') }
                                </button>
                                */
                                }

                                <p>
                                    { t('Do not enter any resources being mobilized under the MARS agreement in this section.') } { t('These resources should be entered for each day they are mobilized until they are demobilized.') }
                                </p>

                                <div className="input-series">
                                    {
                                        formData.hasOwnProperty('mobs_grouped')
                                            ? formData.mobs_grouped.map( (groupItem, groupIndex) => {

                                                const thisObjectRef = "mobs_grouped["+groupIndex+"]";
                                                
                                                let thisInitialDataMobs = { ...initialDataMobs };

                                                // if there is a FIRST in this subgroup, populate the next added item in group with some of same info...
                                                const thisObjectRefFirstInArray = thisObjectRef+"[0]";
                                                const firstInArray = _.get(formData, thisObjectRefFirstInArray);
                                                if ( firstInArray!==undefined ) { thisInitialDataMobs = { ...initialDataMobs, to: firstInArray.to, from: firstInArray.from }; }
                                                
                                                const handleSeriesAddRows = () => seriesAddRow( thisObjectRef, thisInitialDataMobs );

                                                return <div className="input-series-set" key={groupIndex}>
                                                    
                                                    <div className="input-series without-duplicate-labels without-dividing-lines">
                                                        {
                                                            groupItem.map( (item, index) => {

                                                                // look through VALIDATIONs for original array and seet if we can find a MATCH...

                                                                let thisGroupItemValidation = initialDataMobs;
                                                                if ( formStatus.validationErrors.hasOwnProperty('mobs') ) {
                                                                    for (const validationKey in formStatus.validationErrors.mobs) {
                                                                        if ( formData.mobs[validationKey]===item ) {
                                                                            thisGroupItemValidation = { ...thisGroupItemValidation, ...formStatus.validationErrors.mobs[validationKey] };
                                                                        }
                                                                    }
                                                                }

                                                                if (
                                                                    formStatus.validationErrors.hasOwnProperty('mobs_grouped')
                                                                    && formStatus.validationErrors.mobs_grouped.hasOwnProperty(groupIndex)
                                                                    && formStatus.validationErrors.mobs_grouped[groupIndex].hasOwnProperty(index)
                                                                ) {
                                                                    thisGroupItemValidation = { ...thisGroupItemValidation, ...formStatus.validationErrors.mobs_grouped[groupIndex][index] };
                                                                }

                                                                // and make SOME FIELDs restricted.matched to the values of the INITIAL in group...

                                                                let isDisabledAttr = {};
                                                                
                                                                if ( index>0 ) {
                                                                    formData.mobs_grouped[groupIndex][index].to = formData.mobs_grouped[groupIndex][0].to;
                                                                    formData.mobs_grouped[groupIndex][index].from = formData.mobs_grouped[groupIndex][0].from;

                                                                    isDisabledAttr.disabled = "disabled";
                                                                }

                                                                

                                                                // render this series within this GROUP, with it own add BUTTON

                                                                return <div className="input-series-set" key={item.seriesKey+'-'+index}>
                                                                    
                                                                        <fieldset className="input">
                                                                            <div className="input" data-validation={ thisGroupItemValidation.to || null }>
                                                                                <label>{ t('To') }</label>
                                                                                <input type="text" name={"mobs_grouped["+groupIndex+"]["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs_grouped[groupIndex][index].to } {...isDisabledAttr} />
                                                                            </div>

                                                                            <div className="input wide with-input-series-add" data-validation={ thisGroupItemValidation.resources || null }>
                                                                                <ResourceOptionSet inputName={"mobs_grouped["+groupIndex+"]["+index+"].resources"} inputValue={ formData.mobs_grouped[groupIndex][index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />

                                                                                {
                                                                                    index===groupItem.length-1
                                                                                        ? <button className="link input-series-add" type="button" onClick={ handleSeriesAddRows }>
                                                                                            <FontAwesomeIcon icon={ solid('list') } />
                                                                                            { t('Add another item') }
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </div>

                                                                            <div className="input" data-validation={ thisGroupItemValidation.from || null }>
                                                                                <label>{ t('From') }</label>
                                                                                <input type="text" name={"mobs_grouped["+groupIndex+"]["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs_grouped[groupIndex][index].from } {...isDisabledAttr} />
                                                                            </div>
                                                                        </fieldset>

                                                                        <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow( thisObjectRef, item.seriesKey) }>
                                                                            <FontAwesomeIcon icon={ solid('delete-left') } />
                                                                            <span className="button-label">{ t('Remove') }</span>
                                                                        </button>

                                                                    </div>
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            })
                                            : null
                                    }
                                </div>

                                {
                                    // button only needed in editing mode...
                                    isEditing ?
                                        <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("mobs_grouped", [initialDataMobs]) }>
                                            <FontAwesomeIcon icon={ solid('list') } />
                                            { t('Add another item') }
                                        </button>
                                        : null // ... can remain hidden in VIEW mode
                                }

                            </fieldset>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_9) }></div>
                            
                            {
                                /*
                                
                                // SECTION I completed removed for agencies, as requested by client    
                            
                                <fieldset className="form-section">
                                    <legend>{ t('Compact Agreement Demobilization') }</legend>

                                    <p>{ t('Do not enter any resources being mobilized under the MARS agreement in this section.') }</p>

                                    <div className="input-series">
                                        {
                                            formData.demobs.map( (item, index) => (
                                                <div className="input-series-set" key={item.seriesKey}>
                                                    
                                                    <fieldset className="input">
                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].to) : null }>
                                                            <label>{ t('To') }</label>
                                                            <input type="text" name={"demobs["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.demobs[index].to } />
                                                        </div>

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].resources) : null }>
                                                            <label>{ t('Resource') }</label>
                                                            <ResourceOptionSet inputName={"demobs["+index+"].resources"} inputValue={ formData.demobs[index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />
                                                        </div>

                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].from) : null }>
                                                            <label>{ t('From') }</label>
                                                            <input type="text" name={"demobs["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.demobs[index].from } />
                                                        </div>
                                                    </fieldset>

                                                    <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("demobs", item.seriesKey) }>
                                                        <FontAwesomeIcon icon={ solid('delete-left') } />
                                                        <span className="button-label">{ t('Remove') }</span>
                                                    </button>

                                                </div>
                                            ))
                                        }
                                    </div>

                                    <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("demobs", initialDataDemobs) }>
                                        <FontAwesomeIcon icon={ solid('list') } />
                                        { t('Add another item') }
                                    </button>

                                </fieldset>
                                    
                                */
                            }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_10) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Resource Availability Report') }</legend>

                                <div className="input-series">
                                    {
                                        formData.rars.map( (item, index) => (
                                            <div className="input-series-set" key={item.seriesKey}>

                                                <fieldset className="input">
                                                    <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('rars') && formStatus.validationErrors.rars.hasOwnProperty(index) ? t(formStatus.validationErrors.rars[index].available) : null }>
                                                        <label>{ t('Available') }</label>
                                                        <input type="number" name={"rars["+index+"].available"} onChange={handleChange} onBlur={handleBlur} value={ formData.rars[index].available } min="1" />
                                                    </div>

                                                    <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('rars') && formStatus.validationErrors.rars.hasOwnProperty(index) ? t(formStatus.validationErrors.rars[index].resources) : null }>
                                                        <ResourceOptionSet inputName={"rars["+index+"].resources"} inputValue={ formData.rars[index].resources } onBlur={handleBlur} resourceData={resourceData} formData={formData} setFormData={setFormData} useAmounts={false} useLabels={true} />
                                                    </div>
                                                </fieldset>

                                                <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("rars", item.seriesKey) }>
                                                    <FontAwesomeIcon icon={ solid('delete-left') } />
                                                    <span className="button-label">{ t('Remove') }</span>
                                                </button>

                                            </div>
                                        ))
                                    }
                                </div>
                                
                                {
                                    // button only needed in editing mode...
                                    isEditing ?
                                        <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("rars", initialDataRars) }>
                                            <FontAwesomeIcon icon={ solid('list') } />
                                            { t('Add another item') }
                                        </button>
                                        : null // ... can remain hidden in VIEW mode
                                }

                            </fieldset>
                        
                        <h4>{ t('Duty Officer') }</h4>

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_11) }></div>

                            <fieldset className="form-section">
                                <div className="input" data-validation={ t(formStatus.validationErrors.duty_officer) }>
                                    <label>{ t('Duty Officer') }</label>
                                    <input type="text" name="duty_officer" onChange={handleChange} onBlur={handleBlur} value={formData.duty_officer} />
                                </div>

                                <div className="input" data-validation={ t(formStatus.validationErrors.duty_officer_24_hour_cell) }>
                                    <label>{ t('24 Hour Contact Number') }</label>
                                    <input type="text" name="duty_officer_24_hour_cell" onChange={handleChange} onBlur={handleBlur} value={formData.duty_officer_24_hour_cell} />
                                </div>
                            </fieldset>
                        
                    </fieldset>

                    { submitButtons }

                </form>

                <p className="back">
                    <Link to="/agency">
                        <FontAwesomeIcon icon={ solid('arrow-left') } />
                        { t('Overview') }
                    </Link>
                </p>

            </div>

            <Toaster
                  toastOptions={{
                    
                    // Default options
                    className: 'toast-popup',

                    // Default options for specific types
                    error: {
                        duration: 4000,
                        position: 'top-center',

                        className: 'toast-popup toast-error',

                        iconTheme: {
                            primary: 'red',
                            secondary: 'white',
                        },
                    },
                
                    // Default options for specific types
                    success: {
                        duration: 750,
                        position: 'top-center',

                        className: 'toast-popup toast-notify',

                        iconTheme: {
                            primary: 'white',
                            secondary: '#18b4cd',
                        },
                    },
                  }}
            />
        </section>

    );

}

export default AgencySitrepInput;