const baseNode = process.env.REACT_APP_NODE_BASE_URL;
const baseStrapi = process.env.REACT_APP_STRAPI_BASE_URL;
// const baseGeoserver = process.env.REACT_APP_GEOSERVER_BASE_URL; // deprecated, all API calls moved to NODE server (above)

const baseNodeVersion = '/v1';

export default {
    userLogin: () => new URL( baseStrapi + '/api/auth/local' ),
    userProfile: () => new URL( baseStrapi + '/api/users/me?populate=*' ),

    // static CONTENT (intros, etc)
    contentStatic: (pageAlias) => new URL( baseStrapi + `/api/static-contents?filters[alias]=${pageAlias}&populate=localizations&locale=en` ),
    contentStaticPublished: () => new URL( baseStrapi + '/api/static-contents?fields[0]=Alias' ),
    
    // for page NAVIGATION, we'll purposely always use EN so that the URLs don't chnage when switching language
    pagesPublic: () => new URL( baseStrapi + '/api/pages?populate=localizations&locale=en' ),
    pagesAgency: () => new URL( baseStrapi + '/api/agency-pages?populate=localizations&locale=en' ),
    pagesCiffc: () => new URL( baseStrapi + '/api/ciffc-pages?populate=localizations&locale=en' ),

    // for page CONTENT, we'll use LOCALE when parsing the resulting DATA to fetch the correct translations
    pagesPublicContent: (pageAlias) => new URL( baseStrapi + `/api/pages?filters[alias]=${pageAlias}&populate=localizations&locale=en` ),
    pagesAgencyContent: (pageAlias) => new URL( baseStrapi + `/api/agency-pages?filters[alias]=${pageAlias}&populate=localizations&locale=en` ),
    pagesCiffcContent: (pageAlias) => new URL( baseStrapi + `/api/ciffc-pages?filters[alias]=${pageAlias}&populate=localizations&locale=en` ),

    // for public GRAPHING. Originally from GEOSERVER
    firesDaily: (fromDateString, toDateString) => new URL( baseNode + baseNodeVersion + `/dashboard/fires?from_date=${fromDateString}&to_date=${toDateString}` ),
    // firesDaily: (fromDateString, toDateString) => new URL( baseGeoserver + `/ciffc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ciffc:ytd_fires&outputFormat=application%2Fjson&cql_filter=field_situation_report_date BETWEEN '${fromDateString}' AND '${toDateString}' AND field_fire_was_prescribed = '0'` ),
    

    firesMap: () => new URL( baseNode + baseNodeVersion + `/dashboard/fires?map=true` ),
    // firesMap: () => new URL( baseGeoserver + `/ciffc/ows?service=WFS&version=1.0&request=GetFeature&typeName=ciffc:ytd_fires&outputFormat=application/json&SrsName=EPSG:4326&cql_filter=field_stage_of_control_status <> 'OUT' AND field_fire_was_prescribed = '0'` ),
    
    firesWeekly: () => new URL( baseNode + baseNodeVersion + `/dashboard/fires` ),
    // firesWeekly: () => new URL( baseGeoserver + `/ciffc/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ciffc:ytd_fires&outputFormat=application%2Fjson&cql_filter=field_fire_was_prescribed = '0'` ),

    // for public GRAPHING from DIP
    firesHistoricalWeekly: () => new URL( baseNode + baseNodeVersion + `/historical/running` ),
    firesHistoricalYearly: () => new URL( baseNode + baseNodeVersion + `/historical/yearly` ),

    // for DASHBOARD from DIP
    dipAgencies: () => new URL( baseNode + baseNodeVersion + `/agency` ),
    dipSitrepNationalArchive: () => new URL( baseNode + baseNodeVersion + `/sitrep/archive` ),
    dipSitrepNationalLatest: () => new URL( baseNode + baseNodeVersion + `/sitrep/latest` ),
    dipSitrepNational: () => new URL( baseNode + baseNodeVersion + `/sitrep` ),
    dipSummary: () => new URL( baseNode + baseNodeVersion + `/summary` ),
    dipFireCount: () => new URL( baseNode + baseNodeVersion + `/fire-count` ),
    dipWildfires: () => new URL( baseNode + baseNodeVersion + `/wildfires` ),

    // for AGENCIES from DIP
    dipWildfireStats: () => new URL( baseNode + baseNodeVersion + `/wildfire/stats` ),
    dipSitrepStates: () => new URL( baseNode + baseNodeVersion + `/sitrep/stats` ),
    dipSitrepStatesAgency: () => new URL( baseNode + baseNodeVersion + `/sitrep/stats/agency` ), // as of this writing, equivilant to dipSitrepStates()
    dipSitrepStatesNational: () => new URL( baseNode + baseNodeVersion + `/sitrep/stats/national` ),
    dipNationalInventory: () => new URL( baseNode + baseNodeVersion + `/inventory/national` ),

    // for INVENTORY get/post
    dipNationalInventoryInputGet: () => new URL( baseNode + baseNodeVersion + `/inventory/input` ),
    dipNationalInventoryInputValidate: () => new URL( baseNode + baseNodeVersion + `/inventory/input` ),
    dipNationalInventoryInputSave: () => new URL( baseNode + baseNodeVersion + `/inventory/input/save` ),

    // for agency SITREP get/post
    dipSitrepGet: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency` ),
    dipSitrepGetDraft: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency` ), // same as ABOVE (dipSitrepGet), for legacy support and because Wil is an idiot
    dipSitrepGetApproved: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/approved` ),
    dipSitrepGetPublished: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/published` ),
    dipSitrepPostClaim: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/claim` ),
    dipSitrepPostRelease: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/release` ),
    dipSitrepPost: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency` ),
    dipSitrepPostSubmit: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/submit` ),
    dipSitrepPostApprove: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/approve` ),
    dipSitrepPostReject: () => new URL( baseNode + baseNodeVersion + `/sitrep/agency/reject` ),

    // for national SITREP get/post
    dipSitrepNationalGet: () => new URL( baseNode + baseNodeVersion + `/sitrep/national` ),
    dipSitrepNationalGetDraft: () => new URL( baseNode + baseNodeVersion + `/sitrep/national` ), // same as ABOVE (dipSitrepNationalGet), for legacy support and because Wil is an idiot
    dipSitrepNationalGetPublished: () => new URL( baseNode + baseNodeVersion + `/sitrep/national/published` ),
    dipSitrepNationalPostClaim: () => new URL( baseNode + baseNodeVersion + `/sitrep/national/claim` ),
    dipSitrepNationalPostRelease: () => new URL( baseNode + baseNodeVersion + `/sitrep/national/release` ),
    dipSitrepNationalPost: () => new URL( baseNode + baseNodeVersion + `/sitrep/national` ),
    dipSitrepNationalPublish: () => new URL( baseNode + baseNodeVersion + `/sitrep/national/publish` ),

    // misc
    dipResourceOptions: () => new URL( baseNode + baseNodeVersion + '/resource/options' ),
    dipResourceOptionsActive: () => new URL( baseNode + baseNodeVersion + '/resource/options/active' ),
    dipDutyOfficersOptions: () => new URL( baseNode + baseNodeVersion + '/duty-officers' )

}