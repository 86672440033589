// import React from "react";

import i18next from '../i18n';

const FormStatuses = {

    NOTSTARTED: {
        title: i18next.t('Not yet started'),
    
        className: 'notstarted',
    },

    A: {
        title: i18next.t('Approved'),
    
        className: 'approved',
    },

    E: {
        title: i18next.t('Being Edited'),
    
        className: 'editing',
    },

    R: {
        title: i18next.t('Rejected'),
    
        className: 'rejected',
    },

    S: {
        title: i18next.t('Submitted'),
    
        className: 'submitted',
    },

    N: {
        title: i18next.t('Unsubmitted'),
    
        className: 'unsubmitted',
    },

    SYNCING: {
        title: i18next.t('Syncing'),

        className: 'syncing',
    },

    ERROR: {
        title: i18next.t('Error'),

        className: 'error',
    },

    READY: {
        title: i18next.t('Ready to update'),

        className: 'editing',
    },

    BLOCKED: {
        title: i18next.t('Blocked'),

        className: 'error',
    },

    UNPUBLISHED: {
        title: i18next.t('Not published'),

        className: 'unpublished',
    },

    PUBLISHED: {
        title: i18next.t('Published'),

        className: 'published',
    }

}

export default FormStatuses;