import React, { useEffect, useState } from "react";

import AgenciesData from "../../constants/AgenciesData";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts
import { TableFiresActiveAndOut } from "./TableFiresActiveAndOut";
import { TableFiresByCause } from "./TableFiresByCause";
import { TableFiresTotals } from "./TableFiresTotals";
import { TableFiresFullResponse } from "./TableFiresFullResponse";
import { TableMobilizationsToFrom } from "./TableMobilizationsToFrom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function TableFireStatistics( props ){
    
    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    const [sitrepAgenciesSummaries, setSitrepAgenciesSummaries] = useState(); // purposely starts as NO TABLE when no results...

    const [sitrepAgenciesYesterday, setSitrepAgenciesYesterday] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesFull, setSitrepAgenciesFull] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesModified, setSitrepAgenciesModified] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesMonitored, setSitrepAgenciesMonitored] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesGrand, setSitrepAgenciesGrand] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPrescribed, setSitrepAgenciesPrescribed] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPriority, setSitrepAgenciesPriority] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPredictions, setSitrepAgenciesPredictions] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPreparednessLevels, setSitrepAgenciesPreparednessLevels] = useState(<TableWrapper></TableWrapper>);

    const [sitrepAgenciesResourceRequirements, setSitrepAgenciesResourceRequirements] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesHelicopters, setSitrepAgenciesHelicopters] = useState(<TableWrapper></TableWrapper>);
    const [sitrepMobilizationsCompact, setSitrepMobilizationsCompact] = useState(<TableWrapper></TableWrapper>);
    const [sitrepDemobilizationsCompact, setSitrepDemobilizationsCompact] = useState(<TableWrapper></TableWrapper>);
    
    useEffect(() => {

        if ( props.sitrep ) {

            const sitrepData = props.sitrep;
            const agencyFullName = props.agencyFullName || false;
            const agencyLink  = props.agencyLink || false;
            const expandedSitrepData = props.expandedSitrepData || false;

            //! AGENCIES, parse data

            let agencies_summaries = {};

            let agencies_compact_mobs = [];
            let agencies_compact_demobs = [];

            let agencies_yesterday = {};

            let agencies_full = {};

            let agencies_modified = {};

            let agencies_monitored = {};

            let agencies_grand = {};

            let agencies_prescribed = {};

            let agencies_priority_fires = [];

            let agencies_preparedness_levels = {};

            let agencies_helicopters = {};
            let agencies_helicopters_total = {
                light: 0,
                intermediate: 0,
                medium: 0,
                heavy: 0
            }

            for (const k in sitrepData.agencies_sitereps) {
                
                const agency_data = sitrepData.agencies_sitereps[ k ];
                const agency_code = agency_data.sitrep.field_agency_code.toUpperCase();

                // summaries (if any)

                if (
                    agency_data.sitrep.field_en_operations_summary
                    || agency_data.sitrep.field_fr_operations_summary
                ) {
                    
                    if ( !agencies_summaries.hasOwnProperty( agency_code ) ) {
                        agencies_summaries[ agency_code ] = {
                            agency: agency_code,
                            level: '',
                            comments_en: '',
                            comments_fr: ''
                        }
                    }

                    agencies_summaries[ agency_code ].en_summary = agency_data.sitrep.field_en_operations_summary;
                    agencies_summaries[ agency_code ].fr_summary = agency_data.sitrep.field_fr_operations_summary;

                }

                // compact agreement mobs and demobs (if any)

                agencies_compact_mobs = [ ...agencies_compact_mobs, ...agency_data.mobilizations ];
                agencies_compact_demobs = [ ...agencies_compact_demobs, ...agency_data.demobilizations ];

                // yesterday wildfires

                if ( !agencies_yesterday.hasOwnProperty( agency_code ) ) {
                    agencies_yesterday[ agency_code ] = {
                        agency: agency_code,
                        cause_natural: 0,
                        cause_human: 0,
                        cause_undetermined: 0,
                        total: 0.00
                    }
                }

                agencies_yesterday[ agency_code ].cause_natural = agencies_yesterday[ agency_code ].cause_natural + parseInt(agency_data.sitrep.field_today_natural);
                agencies_yesterday[ agency_code ].cause_human = agencies_yesterday[ agency_code ].cause_human + parseInt(agency_data.sitrep.field_today_human);
                agencies_yesterday[ agency_code ].cause_undetermined = agencies_yesterday[ agency_code ].cause_undetermined + parseInt(agency_data.sitrep.field_today_other);
                agencies_yesterday[ agency_code ].total = agencies_yesterday[ agency_code ].cause_natural + agencies_yesterday[ agency_code ].cause_human + agencies_yesterday[ agency_code ].cause_undetermined;

                // agencies full response

                if ( !agencies_full.hasOwnProperty( agency_code ) ) {
                    agencies_full[ agency_code ] = {
                        agency: agency_code,

                        soc_oc: 0,
                        soc_bh: 0,
                        soc_uc: 0,
                        
                        active: 0, 
                        active_area: 0.00, 
                        
                        soc_out: 0,

                        out_area: 0.00,
                        
                        total: 0,
                        total_area: 0.00
                    }
                }

                agencies_full[ agency_code ].soc_oc = agencies_full[ agency_code ].soc_oc + parseInt(agency_data.sitrep.field_full_oc);
                agencies_full[ agency_code ].soc_bh = agencies_full[ agency_code ].soc_bh + parseInt(agency_data.sitrep.field_full_bh);
                agencies_full[ agency_code ].soc_uc = agencies_full[ agency_code ].soc_uc + parseInt(agency_data.sitrep.field_full_uc);
                agencies_full[ agency_code ].active = agencies_full[ agency_code ].soc_oc + agencies_full[ agency_code ].soc_bh + agencies_full[ agency_code ].soc_uc;
                agencies_full[ agency_code ].active_area = agencies_full[ agency_code ].active_area + (parseFloat(agency_data.sitrep.field_full_active_area) || 0);             
                agencies_full[ agency_code ].soc_out = agencies_full[ agency_code ].soc_out + parseInt(agency_data.sitrep.field_full_out);
                agencies_full[ agency_code ].out_area = agencies_full[ agency_code ].out_area + (parseFloat(agency_data.sitrep.field_full_out_area) || 0);
                agencies_full[ agency_code ].total = agencies_full[ agency_code ].active + agencies_full[ agency_code ].soc_out;
                agencies_full[ agency_code ].total_area = agencies_full[ agency_code ].active_area + agencies_full[ agency_code ].out_area;

                // modified response

                if ( !agencies_modified.hasOwnProperty( agency_code ) ) {
                    agencies_modified[ agency_code ] = {
                        agency: agency_code,
                        active: 0,
                        out: 0,
                        total: 0,
                        area: 0.00
                    }
                }

                agencies_modified[ agency_code ].active = agencies_modified[ agency_code ].active + parseInt(agency_data.sitrep.field_mod_active);
                agencies_modified[ agency_code ].out = agencies_modified[ agency_code ].out + parseInt(agency_data.sitrep.field_mod_out);
                agencies_modified[ agency_code ].total = agencies_modified[ agency_code ].active + agencies_modified[ agency_code ].out;
                agencies_modified[ agency_code ].area = agencies_modified[ agency_code ].area + (parseFloat(agency_data.sitrep.field_mod_area) || 0);

                // monitored response

                if ( !agencies_monitored.hasOwnProperty( agency_code ) ) {
                    agencies_monitored[ agency_code ] = {
                        agency: agency_code,
                        active: 0,
                        out: 0,
                        total: 0,
                        area: 0.00
                    }
                }

                agencies_monitored[ agency_code ].active = agencies_monitored[ agency_code ].active + parseInt(agency_data.sitrep.field_mon_active);
                agencies_monitored[ agency_code ].out = agencies_monitored[ agency_code ].out + parseInt(agency_data.sitrep.field_mon_out);
                agencies_monitored[ agency_code ].total = agencies_monitored[ agency_code ].active + agencies_monitored[ agency_code ].out;
                agencies_monitored[ agency_code ].area = agencies_monitored[ agency_code ].area + (parseFloat(agency_data.sitrep.field_mon_area) || 0);

               // grand totals

                if ( !agencies_grand.hasOwnProperty( agency_code ) ) {
                    agencies_grand[ agency_code ] = {
                        agency: agency_code,
                        fires: 0,
                        area: 0.00,

                        // if available, include LIVE CALCULATE ytd values, for comparison/disclaimer
                        carry_over_fires: ( agency_data.hasOwnProperty('carry_over_fires') ? agency_data.carry_over_fires : '' ),
                    }
                }

                agencies_grand[ agency_code ].fires = agencies_grand[ agency_code ].fires + agencies_full[ agency_code ].total + agencies_modified[ agency_code ].total + agencies_monitored[ agency_code ].total;
                agencies_grand[ agency_code ].area = agencies_grand[ agency_code ].area + agencies_full[ agency_code ].total_area + agencies_modified[ agency_code ].area + agencies_monitored[ agency_code ].area;
                
                // prescribed fires

                if ( !agencies_prescribed.hasOwnProperty( agency_code ) ) {
                    agencies_prescribed[ agency_code ] = {
                        agency: agency_code,
                        active: 0,
                        out: 0,
                        total: 0,
                        area: 0.00
                    }
                }

                agencies_prescribed[ agency_code ].active = agencies_prescribed[ agency_code ].active + parseInt(agency_data.sitrep.field_prescribed_active);
                agencies_prescribed[ agency_code ].out = agencies_prescribed[ agency_code ].out + parseInt(agency_data.sitrep.field_prescribed_out);
                agencies_prescribed[ agency_code ].total = agencies_prescribed[ agency_code ].active + agencies_prescribed[ agency_code ].out;
                agencies_prescribed[ agency_code ].area = agencies_prescribed[ agency_code ].area + (parseFloat(agency_data.sitrep.field_prescribed_area) || 0);

                // priority fires
                
                agency_data.priority_fires.forEach(agencyPriorityFire => {
                    let fireToConcat = agencyPriorityFire;
                    fireToConcat.agency = agency_code; // include AGENCY abbrev, not included in priority fires data by default
                    agencies_priority_fires.push( fireToConcat );
                });

                // preparation levels

                if ( !agencies_preparedness_levels.hasOwnProperty( agency_code ) ) {
                    agencies_preparedness_levels[ agency_code ] = {
                        agency: agency_code,
                        level: '',
                        comments_en: '',
                        comments_fr: ''
                    }
                }

                agencies_preparedness_levels[ agency_code ].level = agency_data.sitrep.field_preparedness;
                agencies_preparedness_levels[ agency_code ].comments_en = agency_data.sitrep.field_en_preparedness_comment;
                agencies_preparedness_levels[ agency_code ].comments_fr = agency_data.sitrep.field_fr_preparedness_comment;

                // helicopters

                if ( !agencies_helicopters.hasOwnProperty( agency_code ) ) {
                    agencies_helicopters[ agency_code ] = {
                        light: 0,
                        intermediate: 0,
                        medium: 0,
                        heavy: 0,
                        sum: 0
                    }
                }

                agencies_helicopters[ agency_code ].light = parseInt(agency_data.sitrep.field_casual_helicopter_light) || 0;
                agencies_helicopters[ agency_code ].intermediate = parseInt(agency_data.sitrep.field_casual_helicopter_intermediate) || 0;
                agencies_helicopters[ agency_code ].medium = parseInt(agency_data.sitrep.field_casual_helicopter_medium) || 0;
                agencies_helicopters[ agency_code ].heavy = parseInt(agency_data.sitrep.field_casual_helicopter_heavy) || 0;
                agencies_helicopters[ agency_code ].sum = agencies_helicopters[ agency_code ].light + agencies_helicopters[ agency_code ].intermediate + agencies_helicopters[ agency_code ].medium + agencies_helicopters[ agency_code ].heavy;

                agencies_helicopters_total.light += agencies_helicopters[ agency_code ].light;
                agencies_helicopters_total.intermediate += agencies_helicopters[ agency_code ].intermediate;
                agencies_helicopters_total.medium += agencies_helicopters[ agency_code ].medium;
                agencies_helicopters_total.heavy += agencies_helicopters[ agency_code ].heavy;
                agencies_helicopters_total.sum += agencies_helicopters[ agency_code ].sum;

            }

            //! AGENCIES, display data

            if ( Object.keys(agencies_summaries).length ) {
                setSitrepAgenciesSummaries(
                    <TableWrapper>
                        <thead>
                            <tr>
                                <th>{ t('Agency') }</th>
                                <th>{ t('Operational Summary') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        { 
                            Object.keys(agencies_summaries).map(function(dataKey, index) {

                                const displaySummary = i18n.language === 'fr' ? agencies_summaries[dataKey].fr_summary : agencies_summaries[dataKey].en_summary;
                                
                                // let agency_display_name = t(AgenciesData[dataKey.toLowerCase()].name) ? t(AgenciesData[dataKey.toLowerCase()].name) : AgenciesData[dataKey.toLowerCase()].name;
                                    
                                return <tr key={ index }>
                                    <td>{ dataKey.toUpperCase() }</td>
                                    <td className="nl2br">{ displaySummary }</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </TableWrapper>
                );
            }

            setSitrepMobilizationsCompact( <TableMobilizationsToFrom data={ agencies_compact_mobs } agencyFullName={agencyFullName} agencyLink={agencyLink} /> );

            setSitrepDemobilizationsCompact( <TableMobilizationsToFrom data={ agencies_compact_demobs } agencyFullName={agencyFullName} agencyLink={agencyLink} /> );

            setSitrepAgenciesYesterday( <TableFiresByCause data={agencies_yesterday} agencyFullName={agencyFullName} agencyLink={agencyLink} /> );

            setSitrepAgenciesFull( <TableFiresFullResponse data={agencies_full} agencyFullName={agencyFullName} agencyLink={agencyLink} /> );

            setSitrepAgenciesModified(<TableFiresActiveAndOut data={agencies_modified} agencyFullName={agencyFullName} agencyLink={agencyLink} />);

            setSitrepAgenciesMonitored(<TableFiresActiveAndOut data={agencies_monitored} agencyFullName={agencyFullName} agencyLink={agencyLink} />);

            setSitrepAgenciesGrand(<TableFiresTotals data={agencies_grand} agencyFullName={agencyFullName} agencyLink={agencyLink} />);

            setSitrepAgenciesPrescribed(<TableFiresActiveAndOut data={agencies_prescribed} agencyFullName={agencyFullName} agencyLink={agencyLink} />);

            setSitrepAgenciesPriority(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="center">{ t('Fire') }</th>
                            <th className="center">{ t('Location') }</th>
                            <th className="center" title={ t('Stage of Control') }>{ t('SOC') }</th>
                            <th className="right">{ t('Size') }</th>
                            {
                                expandedSitrepData
                                ? <>
                                        <th className="center">{ t('Incident Type') }</th>
                                        <th className="right">{ t('% Contained') }</th>
                                        {
                                            /*
                                            
                                            // COMMENTS for priority fires repressed/disabled by client request, replaced with publically-viewabled URLs
                                            <th>{ t('Comments') }</th>

                                            */
                                        }
                                    </>
                                : null
                                
                            }
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(agencies_priority_fires).map(function(dataKey, index) {
                            
                            // link to PRIORITY WILDFIRES url if available for agency, else link to main agency WEBSITE instead
                            const agency_code = agencies_priority_fires[dataKey].agency.toLowerCase();
                            const url_priority_wildfires = AgenciesData[ agency_code ].website_priority_wildfires ? AgenciesData[ agency_code ].website_priority_wildfires : AgenciesData[ agency_code ].website;

                            return <tr key={ index }>
                                <td>{ agencies_priority_fires[dataKey].agency }</td>

                                <td className="center">{ agencies_priority_fires[dataKey].field_fire_id }</td>
                                <td className="center"><CiffcNumberFormat type="latlong" number={ agencies_priority_fires[dataKey].field_latitude } />, <CiffcNumberFormat type="latlong" number={ agencies_priority_fires[dataKey].field_longitude } /></td>
                                <td className="center">{ agencies_priority_fires[dataKey].field_stage_of_control }</td>
                                <td className="right"><CiffcNumberFormat type="ha" number={ parseFloat(agencies_priority_fires[dataKey].field_size) || 0 } /></td>
                                {
                                    expandedSitrepData
                                    ? <>
                                            <td className="center">{ agencies_priority_fires[dataKey].field_incident_type }</td>
                                            <td className="right">{ agencies_priority_fires[dataKey].field_percent_contained }</td>
                                            {
                                                /*
                                                
                                                // COMMENTS for priority fires repressed/disabled by client request, replaced with publically-viewabled URLs
                                                <td className="nl2br">{ i18n.language === 'fr' ? agencies_priority_fires[dataKey].field_fr_comments : agencies_priority_fires[dataKey].field_en_comments }</td>

                                                */
                                            }
                                        </>
                                    : null
                                
                                }
                                <td className="right">
                                    <a href={ url_priority_wildfires } target="_blank" rel="noopener noreferrer">
                                        <span className="more-label">{ t('more information') }</span>
                                        <FontAwesomeIcon icon={ solid('arrow-up-right-from-square') } />
                                    </a>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </TableWrapper>
            );

            setSitrepAgenciesPredictions(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="right">{ t('Natural') }</th>
                            <th className="right">{ t('Human') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(sitrepData.agencies_sitereps).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }

                                if ( sitrepData.agencies_sitereps[dataKey].sitrep.field_occurrence_prediction_lightning || sitrepData.agencies_sitereps[dataKey].sitrep.field_occurrence_prediction_human ) {
                                    return <tr key={index}>
                                        <td>{ agency_display_name }</td>
                                        <td className="right">{ sitrepData.agencies_sitereps[dataKey].sitrep.field_occurrence_prediction_lightning }</td>
                                        <td className="right">{ sitrepData.agencies_sitereps[dataKey].sitrep.field_occurrence_prediction_human }</td>
                                    </tr>
                                }

                                return null;
                            })
                        }
                    </tbody>
                </TableWrapper>
            );

            setSitrepAgenciesPreparednessLevels(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="right">{ t('APL') }</th>
                            {
                                /*

                                // DEPRECATED by client, Feb 2023

                                expandedSitrepData
                                ? <>
                                        <th>{ t('Comments') }</th>
                                    </>
                                : null

                                */
                            }
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(agencies_preparedness_levels).map(function(dataKey, index) {

                            // DEPRECATED by client, Feb 2023
                            // const displayPreparednessComments = i18n.language === 'fr' ? agencies_preparedness_levels[dataKey].comments_fr : agencies_preparedness_levels[dataKey].comments_en;
                            
                            // AGENCY ...and translate, if available
                            let agency_display_name = t(AgenciesData[dataKey.toLowerCase()].name) ? t(AgenciesData[dataKey.toLowerCase()].name) : AgenciesData[dataKey.toLowerCase()].name;
                                
                            return <tr key={ index }>
                                <td>{ agency_display_name }</td>
                                
                                <td className="right">{
                                    agencies_preparedness_levels[dataKey].level
                                        ? <span className={ 'apl-lvl-' + agencies_preparedness_levels[dataKey].level + '-light' }>{ agencies_preparedness_levels[dataKey].level }</span>
                                        : null
                                }</td>
                                
                                {
                                    /*

                                    // DEPRECATED by client, Feb 2023
                                    
                                    expandedSitrepData
                                    ? <>
                                            <td className="nl2br">{ displayPreparednessComments }</td>
                                        </>
                                    : null

                                    */
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </TableWrapper>
            );

            setSitrepAgenciesResourceRequirements(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>

                            {
                                expandedSitrepData 
                                    ? <><th>{ t('Requirements') } (English)</th><th>{ t('Requirements') } (Français)</th></>
                                    : <th>{ t('Requirements') }</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(sitrepData.agencies_sitereps).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }

                                // if EXPANDED, display BOTH languages (if EITHER has any data to show)...
                                if (
                                    expandedSitrepData
                                    && (
                                        sitrepData.agencies_sitereps[dataKey].sitrep.field_en_possibility_of_resources
                                        || sitrepData.agencies_sitereps[dataKey].sitrep.field_fr_possibility_of_resources
                                    )
                                ) {
                                    return <tr key={index}>
                                        <td>{ agency_display_name }</td>
                                        <td className="nl2br">{ sitrepData.agencies_sitereps[dataKey].sitrep.field_en_possibility_of_resources }</td>
                                        <td className="nl2br">{ sitrepData.agencies_sitereps[dataKey].sitrep.field_fr_possibility_of_resources }</td>
                                    </tr>
                                } 
                                
                                // ...otherwise, show only ONE language (the preferred/set)
                                else {
                                    const displayResourceComments = i18n.language === 'fr' ? sitrepData.agencies_sitereps[dataKey].sitrep.field_fr_possibility_of_resources : sitrepData.agencies_sitereps[dataKey].sitrep.field_en_possibility_of_resources;

                                    if ( displayResourceComments ) {
                                        return <tr key={index}>
                                            <td>{ agency_display_name }</td>
                                            <td className="nl2br">{ displayResourceComments }</td>
                                        </tr>
                                    }
                                } 

                                // fallback, if we find a ROW without any values/data to show
                                return null;

                            })
                        }
                    </tbody>
                </TableWrapper>
            );

            setSitrepAgenciesHelicopters(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="right">{ t('Light') }</th>
                            <th className="right">{ t('Intermediate') }</th>
                            <th className="right">{ t('Medium') }</th>
                            <th className="right">{ t('Heavy') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(agencies_helicopters).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }
                                
                                // only listing TABLE ROWS when any data to display (skipping full zoer rows)
                                if ( agencies_helicopters[ dataKey ].sum ) {
                                    return <tr key={index}>
                                        <td>{ agency_display_name }</td>
                                        <td className="right empty-integer"><CiffcNumberFormat type="int" number={agencies_helicopters[ dataKey ].light} /></td>
                                        <td className="right empty-integer"><CiffcNumberFormat type="int" number={agencies_helicopters[ dataKey ].intermediate} /></td>
                                        <td className="right empty-integer"><CiffcNumberFormat type="int" number={agencies_helicopters[ dataKey ].medium} /></td>
                                        <td className="right empty-integer"><CiffcNumberFormat type="int" number={agencies_helicopters[ dataKey ].heavy} /></td>
                                    </tr>;
                                }

                                return null;

                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{ t('Total') }</th>
                            <th className="right empty-integer"><CiffcNumberFormat type="int" number={ agencies_helicopters_total.light } /></th>
                            <th className="right empty-integer"><CiffcNumberFormat type="int" number={ agencies_helicopters_total.intermediate } /></th>
                            <th className="right empty-integer"><CiffcNumberFormat type="int" number={ agencies_helicopters_total.medium } /></th>
                            <th className="right empty-integer"><CiffcNumberFormat type="int" number={ agencies_helicopters_total.heavy } /></th>
                        </tr>
                    </tfoot>
                </TableWrapper>
            );

        }

    }, [ props.sitrep, t ]);

    // return JSON of values

    return {
        sitrepAgenciesSummaries: sitrepAgenciesSummaries,

        sitrepAgenciesYesterday: sitrepAgenciesYesterday,
        sitrepAgenciesFull: sitrepAgenciesFull,
        sitrepAgenciesModified: sitrepAgenciesModified,
        sitrepAgenciesMonitored: sitrepAgenciesMonitored,
        sitrepAgenciesGrand: sitrepAgenciesGrand,
        sitrepAgenciesPrescribed: sitrepAgenciesPrescribed,
        sitrepAgenciesPriority: sitrepAgenciesPriority,
        sitrepAgenciesPredictions: sitrepAgenciesPredictions,
        sitrepAgenciesPreparednessLevels: sitrepAgenciesPreparednessLevels,

        sitrepAgenciesResourceRequirements: sitrepAgenciesResourceRequirements,
        sitrepAgenciesHelicopters: sitrepAgenciesHelicopters,
        sitrepMobilizationsCompact: sitrepMobilizationsCompact,
        sitrepDemobilizationsCompact: sitrepDemobilizationsCompact,
    }

}

export { TableFireStatistics };

function TableFireStatisticsAgenciesSummaries( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesSummaries;
}

function TableFireStatisticsAgenciesYesterday( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesYesterday;
}

function TableFireStatisticsAgenciesFull( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesFull;
}

function TableFireStatisticsAgenciesModified( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesModified;
}

function TableFireStatisticsAgenciesMonitored( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesMonitored;
}

function TableFireStatisticsAgenciesGrand( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesGrand;
}

function TableFireStatisticsAgenciesPrescribed( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesPrescribed;
}

function TableFireStatisticsAgenciesPriority( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesPriority;
}

function TableFireStatisticsAgenciesPredictions( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesPredictions;
}

function TableFireStatisticsAgenciesPreparednessLevels( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesPreparednessLevels;
}

function TableFireStatisticsAgenciesResourceRequirements( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesResourceRequirements;
}

function TableFireStatisticsAgenciesHelicopters( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepAgenciesHelicopters;
}

function TableFireStatisticsMobilizationsCompact( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepMobilizationsCompact;
}

function TableFireStatisticsDemobilizationsCompact( props ){
    const calculatedTables = TableFireStatistics( props );
    return calculatedTables.sitrepDemobilizationsCompact;
}

export {
    TableFireStatisticsAgenciesSummaries,

    TableFireStatisticsAgenciesYesterday,
    TableFireStatisticsAgenciesFull,
    TableFireStatisticsAgenciesModified,
    TableFireStatisticsAgenciesMonitored,
    TableFireStatisticsAgenciesGrand,
    TableFireStatisticsAgenciesPriority,
    TableFireStatisticsAgenciesPrescribed,
    TableFireStatisticsAgenciesPredictions,
    TableFireStatisticsAgenciesPreparednessLevels,

    TableFireStatisticsAgenciesResourceRequirements,
    TableFireStatisticsAgenciesHelicopters,
    TableFireStatisticsMobilizationsCompact,
    TableFireStatisticsDemobilizationsCompact
};