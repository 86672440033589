import React, { useEffect, useState } from "react";

import AgenciesData from "../../constants/AgenciesData";
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { useTranslation } from 'react-i18next';

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableFiresActiveAndOut( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    useEffect(() => {

        if ( props.data ) {

            const data = props.data;
            
            let tableTotals = {
                agency: t('Total'),

                active: 0, 
                out: 0,

                total: 0,
                area: 0.00
            };

            Object.keys(data).map(function(dataKey, index) {
                tableTotals.active = tableTotals.active + parseInt(data[ dataKey ].active);
                tableTotals.out = tableTotals.out + parseInt(data[ dataKey ].out);
                tableTotals.total = tableTotals.total + parseInt(data[ dataKey ].total);
                tableTotals.area = tableTotals.area + parseFloat(data[ dataKey ].area);
                return true;
            });

            setTableDisplay(
                <>
                    <tbody>
                        { 
                            Object.keys(data).map(function(dataKey, index) {

                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }

                                return <tr key={ index }>
                                    <td>{ agency_display_name }</td>

                                    <td className="right"><CiffcNumberFormat type="int" number={ data[dataKey].active } /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={ data[dataKey].out } /></td>
                                    
                                    <td className="right"><CiffcNumberFormat type="int" number={ data[dataKey].total } /></td>
                                    <td className="right"><CiffcNumberFormat number={ data[dataKey].area } /></td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{ tableTotals.agency }</th>

                            <th className="right"><CiffcNumberFormat type="int" number={ tableTotals.active } /></th>
                            <th className="right"><CiffcNumberFormat type="int" number={ tableTotals.out } /></th>

                            <th className="right"><CiffcNumberFormat type="int" number={ tableTotals.total } /></th>
                            <th className="right"><CiffcNumberFormat type="ha" number={ tableTotals.area } /></th>
                        </tr>
                    </tfoot>
                </>
            );

        }

    }, [ props.agencies, props.data, t ]);


    const [tableDisplay, setTableDisplay] = useState(); 

    return(
        <TableWrapper>
            <thead>
                <tr>
                    <th>{ t('Agency') }</th>

                    <th className="w15 right">{ t('Active') }</th>
                    <th className="w15 right">{ t('Out') }</th>

                    <th className="w15 right">{ t('Total') }</th>
                    <th className="w15 right">{ t('Hectares') }</th>
                </tr>
            </thead>
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableFiresActiveAndOut };