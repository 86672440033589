import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment';

import FormStatuses from "../constants/FormStatuses"; 
import AgenciesData from "../constants/AgenciesData";
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

import { ReactComponent as Map } from '../images/Map.svg';

// CONTENT
import StaticContent from "./elements/StaticContent";

function AgencyOverview( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Overview') );

    // INTERVAL amount for re-fetching SITREP statuses (in milliseconds)
    const intervalAmount = 1 * 60 * 1000;

    // is API access available?
    const [isApi, setIsApi] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(false);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;
    const [ isCiffc, setIsCiffc] = useState(false);
    const [ agencyCode, setAgencyCode] = useState(false);
    
    // REDIRECT browser out of autheticated area if user not LOGGED IN

    const history = useHistory();

    useEffect(() => {

        // recognize if LOGGED in
        
        if (
            !isLoggedIn
            || !activeUser
            || activeUser.user === undefined
            || activeUser.role === undefined
        ) {
            history.push("/login");
        } else {
        
            // figure out AGENCY CODE associated with logged-in user (if any)

            if ( 
                // and has a recognized AUTH role
                activeUser.user.role
                && activeUser.user.role.name
                && activeUser.user.role.name.toUpperCase() === 'AUTHENTICATED'

                // and has a recognized AGENCY code
                && activeUser.user !== undefined 
                && activeUser.user.agency_code
                && AgenciesData.hasOwnProperty( activeUser.user.agency_code.toLowerCase() )
            ) {
                setAgencyCode( activeUser.user.agency_code );
            }

            // recognize if logged in as CIFFC role

            else if (
                activeUser
                && activeUser.role !== undefined 
                && activeUser.role.name !== undefined 
                && activeUser.role.name.toUpperCase() === 'CIFFC'
            ) {
                setIsCiffc(true);
            }

            // if FAILed both tries in login check...

            else {
                history.push("/agency");
            }

        }  

    }, [ isLoggedIn ]);
    
    const [wildfireData, setWildfireData] = useState();
    const [sitrepStateData, setSitrepStateData] = useState();
    const [sitrepNationalStateData, setSitrepNationalStateData] = useState();

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {

            const urlWildfires = ApiEndpoints.dipWildfireStats();
            
            axios
                .get(
                    urlWildfires,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setWildfireData(data);
                })
                .catch((error) => {
                    console.log(error);
                });

            const urlSitreps = ApiEndpoints.dipSitrepStates();
                
            axios
                .get(
                    urlSitreps,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setSitrepStateData(data);

                    // flag API as accessible
                    setIsApi(true);

                    // set API last update
                    setLastUpdated( moment() );
                })
                .catch((error) => {
                    console.log('ERROR from urlSitreps()', error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);

                    // set API last update
                    setLastUpdated( moment() );
                });
            
            const urlNationalSitreps = ApiEndpoints.dipSitrepStatesNational();
            
            axios
                .get(
                    urlNationalSitreps,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setSitrepNationalStateData(data);

                    // flag API as accessible
                    setIsApi(true);

                    // set API last update
                    setLastUpdated( moment() );
                })
                .catch((error) => {
                    console.log('ERROR from urlNationalSitreps()', error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);

                    // set API last update
                    setLastUpdated( moment() );
                });

        }

    }, [ isLoggedIn ]);

    // same calls as INITIAL above, but on an TIMED INTERVAL to keep the view "live"

    useEffect(() => {
        let interval = setInterval(() => {
            
            if ( isLoggedIn && isApi ) {

                const urlSitreps = ApiEndpoints.dipSitrepStates();
                
                axios
                    .get(
                        urlSitreps,
                        {
                            headers: {
                                Authorization: "Bearer " + activeUser.jwt
                            }
                        }
                    )
                    .then(({ data }) => {
                        setSitrepStateData(data);

                        // flag API as accessible
                        setIsApi(true);

                        // set API last update
                        setLastUpdated( moment() );
                    })
                    .catch((error) => {
                        console.log('ERROR from urlSitreps()', error);

                        // flag API as inaccessible, show WARNING
                        setIsApi(false);

                        // set API last update
                        setLastUpdated( moment() );
                    });

                if ( isCiffc ) {
                
                    const urlNationalSitreps = ApiEndpoints.dipSitrepStatesNational();
                    
                    axios
                        .get(
                            urlNationalSitreps,
                            {
                                headers: {
                                    Authorization: "Bearer " + activeUser.jwt
                                }
                            }
                        )
                        .then(({ data }) => {
                            setSitrepNationalStateData(data);
    
                            // flag API as accessible
                            setIsApi(true);
    
                            // set API last update
                            setLastUpdated( moment() );
                        })
                        .catch((error) => {
                            console.log('ERROR from urlNationalSitreps()', error);
    
                            // flag API as inaccessible, show WARNING
                            setIsApi(false);
    
                            // set API last update
                            setLastUpdated( moment() );
                        });
    
                }
            }

        }, intervalAmount );

        return () => {
            clearInterval(interval);
        };
    }, [ isLoggedIn, isApi ]);


    const [tableCiffcSitrepStatuses, setTableCiffcSitrepStatuses] = useState();
    const [tableNationalSitrepStatuses, setTableNationalSitrepStatuses] = useState();
    const [tableFireStats, setTableFireStats] = useState();

    useEffect(() => {
        if ( sitrepStateData ) {

            // console.log('sitrepStateData', sitrepStateData);
            
            setTableCiffcSitrepStatuses(
                <>
                    {
                        Object.keys(sitrepStateData).map(function(dataKey, index) {

                            // console.log( sitrepStateData[dataKey] );

                            const province_id = 'CA-' + sitrepStateData[dataKey].agency.toUpperCase();
                            let province_state = sitrepStateData[dataKey].status.toUpperCase();
                            const province_url = '/agency/sitrep/' + sitrepStateData[dataKey].agency.toLowerCase();

                            // AGENCY ...and translate, if available
                            const agency_display_name = t(AgenciesData[ sitrepStateData[dataKey].agency ].name) ? t(AgenciesData[ sitrepStateData[dataKey].agency ].name) : AgenciesData[ sitrepStateData[dataKey].agency ].name;
                            
                            // define DRAFT siterep STATE for display

                            let agency_sitrep_status = t(FormStatuses['NOTSTARTED'].title);
                            let agency_sitrep_class = FormStatuses['NOTSTARTED'].className;
                            let agency_sitrep_version = false;
                            let agency_sitrep_icon = <FontAwesomeIcon icon={ regular('file-lines') } />; // display for TABLE icon and link configure

                            if (
                                province_state
                                && FormStatuses.hasOwnProperty(province_state)
                                && sitrepStateData[dataKey].hasOwnProperty('version')
                                && sitrepStateData[dataKey].version!==null
                                && sitrepStateData[dataKey].version!=='0'
                                && parseFloat(sitrepStateData[dataKey].version)>0
                            ) {
                                agency_sitrep_status = t(FormStatuses[province_state].title);
                                agency_sitrep_class = FormStatuses[province_state].className;
                                agency_sitrep_version = sitrepStateData[dataKey].version + '';
                            }

                            // ...if currently being EDITED, update state and icon to indicate

                            if (
                                sitrepStateData[dataKey].hasOwnProperty('claimed_by')
                                && sitrepStateData[dataKey].claimed_by!==null
                                && sitrepStateData[dataKey].claimed_by!=='0'
                                && parseInt(sitrepStateData[dataKey].claimed_by)>0
                            ) {
                                province_state = 'E'; // override DEFAULT state with BEING-EDITED pseudo-state for display

                                agency_sitrep_status = t(FormStatuses['E'].title);
                                agency_sitrep_class = FormStatuses['E'].className;

                                if ( sitrepStateData[dataKey].claimed_by === activeUser.user.id ) {
                                    agency_sitrep_icon = <FontAwesomeIcon icon={ solid('file-edit') } />;
                                    agency_sitrep_status += ', ' + t('by you');
                                } else {
                                    agency_sitrep_icon = <FontAwesomeIcon icon={ solid('lock') } />;
                                    agency_sitrep_status += ', ' + t('by agency');
                                }
                            }
                                
                            // define APPROVAL sitrep STATE for display

                            let province_approved_url = false;
                            let agency_approved_sitrep_class = FormStatuses['NOTSTARTED'].className;
                            let agency_approved_sitrep_version = false;

                            if (
                                sitrepStateData[dataKey].hasOwnProperty('approved_version')
                                && sitrepStateData[dataKey].approved_version!==null
                                && sitrepStateData[dataKey].approved_version!=='0'
                                && parseFloat(sitrepStateData[dataKey].approved_version)>0
                            ) {
                                province_approved_url = '/agency/sitrep/' + sitrepStateData[dataKey].agency.toLowerCase() + '/approved';
                                agency_approved_sitrep_class = 'approved';
                                agency_approved_sitrep_version = sitrepStateData[dataKey].approved_version + '';
                            }
                            
                            // define PUBLISHED sitrep STATE for display

                            let province_published_url = false;
                            let agency_published_sitrep_class = FormStatuses['NOTSTARTED'].className;
                            let agency_published_sitrep_version = false;

                            if (
                                sitrepStateData[dataKey].hasOwnProperty('published_version')
                                && sitrepStateData[dataKey].published_version!==null
                                && sitrepStateData[dataKey].published_version!=='0'
                                && parseFloat(sitrepStateData[dataKey].published_version)>0
                            ) {
                                province_published_url = '/agency/sitrep/' + sitrepStateData[dataKey].agency.toLowerCase() + '/published';
                                agency_published_sitrep_class = 'approved';
                                agency_published_sitrep_version = sitrepStateData[dataKey].published_version + '';
                            }

                            // define the display CONNECTION ARROWS, to better illustrate if various sitrep versions have been promoted

                            let icon_draft_to_approved = <>&nbsp;</>;
                            let icon_approved_to_published = <>&nbsp;</>;

                            if (
                                agency_approved_sitrep_version
                                && agency_approved_sitrep_version===agency_sitrep_version
                            ) {
                                icon_draft_to_approved = <FontAwesomeIcon icon={ solid('arrow-right') } />
                            } else if (
                                agency_approved_sitrep_version
                                && agency_approved_sitrep_version!==agency_sitrep_version
                            ) {
                                icon_draft_to_approved = <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon={ solid('arrow-right') } color="#eee" />
                                    <FontAwesomeIcon icon={ solid('slash') } color="#ccc" />
                                </span>
                            }

                            if (
                                agency_published_sitrep_version
                                && agency_published_sitrep_version===agency_approved_sitrep_version
                            ) {
                                icon_approved_to_published = <FontAwesomeIcon icon={ solid('arrow-right') } />
                            } else if (
                                agency_published_sitrep_version
                                && agency_published_sitrep_version!==agency_approved_sitrep_version
                            ) {
                                icon_approved_to_published = <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon={ solid('arrow-right') } color="#eee" />
                                    <FontAwesomeIcon icon={ solid('slash') } color="#ccc" />
                                </span>
                            }

                            // update MAP SVG element with clickable links (if possible, as CIFFC user will not use MAP as of JAN 2025)...
                            
                            if ( !isCiffc ) {

                                const province_element = document.getElementById( province_id );
                
                                if ( province_element ) {

                                    // update BACKGROUND with current color
                                    if (
                                        province_state
                                        && FormStatuses.hasOwnProperty(province_state)
                                    ) {
                                        province_element.classList = FormStatuses[province_state].className;
                                    } else {
                                        province_element.classList = FormStatuses['N'].className; // map defaults to UNSUBMITTED when not-started
                                    }
                
                                    /*
                                    // IF allowed to click/access an agency sitrep input...
                                    // FEB 2025, DEPRECATE, always allow clicking if has access, let CLAIM state in INPUT view handle if allowed to edit
                                    if (
                                        !sitrepStateData[dataKey].edited_by
                                        || (
                                            sitrepStateData[dataKey].edited_by
                                            && sitrepStateData[dataKey].edited_by === activeUser.user.id
                                        )
                                        || (
                                            sitrepStateData[dataKey].edited_by
                                            && sitrepStateData[dataKey].edited_by !== activeUser.user.id
                                            && province_state 
                                            && province_state!=='E'
                                        )
                                    ) {

                                        // attach CLASS for hover state
                                        province_element.classList.add('approval-map-can-hover');
                
                                        // attach CLICK event
                                        province_element.addEventListener('click', () => {
                                            history.push(province_url);
                                        });
                                    }
                                    */

                                    // attach CLASS for hover state
                                    province_element.classList.add('approval-map-can-hover');
                
                                    // attach CLICK event
                                    province_element.addEventListener('click', () => {
                                        history.push(province_url);
                                    });
                                    
                                }
                                
                                else {

                                    console.log("Can't find #ID for "+province_id );

                                }

                            }
            
                            return <tr key={ index }>
                                <td>
                                    { agency_sitrep_icon }
                                    &nbsp;&nbsp;
                                    <Link className="bold" to={ province_url }>
                                        { agency_display_name }
                                    </Link>
                                </td>

                                <td className="right"><span className={ agency_sitrep_class }>{ agency_sitrep_status }</span></td>
                                <td style={{width: '8%'}} className="right"><span className={ agency_sitrep_class }>{ !agency_sitrep_version ? '-' : <Link to={ province_url }>{ t('ver.') }&nbsp;{agency_sitrep_version}</Link> }</span></td>

                                <td className="right">{ icon_draft_to_approved }</td>
                                <td style={{width: '8%'}} className="right"><span className={ agency_approved_sitrep_class }>{ !agency_approved_sitrep_version ? '-' : <Link to={ province_approved_url }>{ t('ver.') }&nbsp;{agency_approved_sitrep_version}</Link> }</span></td>

                                <td className="right">{ icon_approved_to_published }</td>
                                <td style={{width: '8%'}} className="right"><span className={ agency_published_sitrep_class }>{ !agency_published_sitrep_version ? '-' : <Link to={ province_published_url }>{ t('ver.') }&nbsp;{agency_published_sitrep_version}</Link> }</span></td>
                            </tr>

                        })
                    }
                </>
            );

        } else {
            setTableCiffcSitrepStatuses(null);
            setTableFireStats(null);
        }
    }, [ sitrepStateData ]);

    useEffect(() => {
        if ( sitrepNationalStateData ) {

            // console.log('sitrepNationalStateData', sitrepNationalStateData);

            // define DEFAULT return...

            const current_national_sitrep_date = moment().tz( moment.tz.guess() ).format('MMMM D, YYYY');
            const current_national_sitrep_date_for_url = moment().tz( moment.tz.guess() ).format('YYYY-DD-MM');
            
            let draft_national_icon = <FontAwesomeIcon icon={ regular('file-lines') } />;
            let draft_national_status = t(FormStatuses['NOTSTARTED'].title);
            let draft_national_class = FormStatuses['NOTSTARTED'].className;
            let draft_national_version = false;
            let currentApprovedAgencySitrepCount = 0;
            let published_national_class = FormStatuses['NOTSTARTED'].className;
            let published_national_version = false;

            if (
                sitrepNationalStateData.hasOwnProperty('version') 
                && sitrepNationalStateData.version!==null
                && sitrepNationalStateData.version!==false
                && sitrepNationalStateData.version!==0
                && sitrepNationalStateData.version!=='0'
                && sitrepNationalStateData.version!==''
            ) {
                draft_national_version = sitrepNationalStateData.version + '';
            }

            if (
                sitrepNationalStateData.hasOwnProperty('claimed_by')
                && sitrepNationalStateData.claimed_by!==null
                && sitrepNationalStateData.claimed_by!==false
                && sitrepNationalStateData.claimed_by!==''
                && parseInt(sitrepNationalStateData.claimed_by)>0
            ) {
                draft_national_icon = <FontAwesomeIcon icon={ solid('file-edit') } />;
                draft_national_status = t(FormStatuses['E'].title);
                draft_national_class = FormStatuses['E'].className;
            } else if (
                sitrepNationalStateData.hasOwnProperty('is_published')
            ) {
                if ( sitrepNationalStateData.is_published ) {
                    draft_national_status = t(FormStatuses.PUBLISHED.title);
                    draft_national_class = FormStatuses.PUBLISHED.className;
                } else {
                    draft_national_status = t(FormStatuses.UNPUBLISHED.title);
                    draft_national_class = FormStatuses.UNPUBLISHED.className;
                }
            }
            
            if (
                sitrepStateData
                && Array.isArray(sitrepStateData)
                && sitrepStateData.length>0
            ) {

                Object.keys(sitrepStateData).map(function(dataKey, index) {
                    // console.log('sitrepStateData', sitrepStateData[dataKey]);

                    if (
                        sitrepStateData[dataKey].hasOwnProperty('approved_version')
                        && sitrepStateData[dataKey].approved_version!==null
                        && sitrepStateData[dataKey].approved_version!==false
                        && sitrepStateData[dataKey].approved_version!=='0'
                        && sitrepStateData[dataKey].approved_version!==''
                        && sitrepStateData[dataKey].approved_version!==0
                    ) {
                        currentApprovedAgencySitrepCount++;
                    }

                    return null;
                });
            }

            if (
                sitrepNationalStateData.hasOwnProperty('published_version') 
                && sitrepNationalStateData.published_version!==null
                && sitrepNationalStateData.published_version!==false
                && sitrepNationalStateData.published_version!==0
                && sitrepNationalStateData.published_version!=='0'
                && sitrepNationalStateData.published_version!==''
            ) {
                published_national_class = FormStatuses.PUBLISHED.className;
                published_national_version = sitrepNationalStateData.published_version + '';
            }

            // define CONNECTION ARROWS to better illustrate the sitrep being PROMOTED

            let icon_draft_to_published = <>&nbsp;</>;

            if (
                published_national_version
                && published_national_version===draft_national_version
            ) {
                icon_draft_to_published = <FontAwesomeIcon icon={ solid('arrow-right') } />
            } else if (
                published_national_version
                && published_national_version!==draft_national_version
            ) {
                icon_draft_to_published = <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={ solid('arrow-right') } color="#eee" />
                    <FontAwesomeIcon icon={ solid('slash') } color="#ccc" />
                </span>
            }

            setTableNationalSitrepStatuses(
                <tr>
                    <td>
                        {draft_national_icon}
                        &nbsp;&nbsp;
                        <Link className="bold" to="/agency/national">{ current_national_sitrep_date }</Link>
                    </td>
                    <td className="right"><span className={ draft_national_class }>{ draft_national_status }</span></td>
                    <td style={{width: '8%'}} className="right"><span className={ draft_national_class }>{ !draft_national_version ? '-' : <Link to="/agency/national">{ t('ver.') }&nbsp;{draft_national_version}</Link> }</span></td>

                    <td className="right">{ icon_draft_to_published }</td>
                    <td style={{width: '8%'}} className="right">{ currentApprovedAgencySitrepCount ? <>{ currentApprovedAgencySitrepCount }&nbsp;/&nbsp;13</> : <span className={ FormStatuses['NOTSTARTED'].className }>0&nbsp;/&nbsp;13</span> }</td>

                    <td className="right">{ icon_draft_to_published }</td>
                    <td style={{width: '8%'}} className="right"><span className={ published_national_class }>{ !published_national_version ? '-' : <Link to={ '/situation/' + current_national_sitrep_date_for_url }>{ t('ver.') }&nbsp;{published_national_version}</Link> }</span></td>
                </tr>
            );

        } else {
            setTableNationalSitrepStatuses(null);
        }
    }, [ sitrepNationalStateData, sitrepStateData ]);

    useEffect(() => {
        if ( wildfireData ) {
            setTableFireStats(
                <>
                    <tbody>
                        { 
                            Object.keys(wildfireData).map(function(dataKey, index) {

                                // AGENCY ...and translate, if available
                                let agency_display_name = t(AgenciesData[ wildfireData[dataKey].agency ].name) ? t(AgenciesData[ wildfireData[dataKey].agency ].name) : AgenciesData[ wildfireData[dataKey].agency ].name;
                                
                                return <tr key={ index }>
                                    <td>
                                        <a href={ AgenciesData[ wildfireData[dataKey].agency ].website } target="_blank" rel="noopener noreferrer">
                                            { agency_display_name }
                                        </a>
                                    </td>
                                    
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData[dataKey].ytd_fires } /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData[dataKey].fires_today } /></td>
                                </tr>
                            })
                        }
                    </tbody>
                </>
            );
        } else {
            setTableFireStats(null);
        }
    }, [ wildfireData, i18n.language ]);

    return(
        <section className="dashboard agency">

            <div className="container">
                <StaticContent staticContentAlias="agency-overview" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                { 

                    /* JAN 2024, updated UI for AGENCY login */
                    
                    !!agencyCode && !isCiffc ?

                        <div className="dashboard-row not-stretched">
                            <div className="dashboard-column wide">

                                <div className="dashboard-card">
                                    <div id="approval-map-wrapper">
                                        <Map />
                                    
                                        <ul id="approval-map-legend">
                                            <li><span className="swatch"></span>{ FormStatuses['N'].title }</li>
                                            <li><span className="swatch editing"></span>{ FormStatuses['E'].title }</li>
                                            <li><span className="swatch submitted"></span>{ FormStatuses['S'].title }</li>
                                            <li><span className="swatch rejected"></span>{ FormStatuses['R'].title }</li>
                                            <li><span className="swatch approved"></span>{ FormStatuses['A'].title }</li>
                                        </ul>

                                    </div>
                                </div>

                            </div>
                            
                            <div className="dashboard-column">
                                <div className="dashboard-card flat">
                                    <table className="approval-map-table">
                                        <thead>
                                            <tr>
                                                <th>{ t('Fires') }</th>

                                                <th className="right">{ t('YTD') }</th>
                                                <th className="right">{ t('Today') }</th>
                                            </tr>
                                        </thead>
                                        { tableFireStats }
                                    </table>
                                </div>
                            </div>
                        </div>
                        : null
                }

                {

                    /* JAN 2024, updated UI for CIFFC login */

                    !agencyCode && !!isCiffc ?
                        
                        <div className="dashboard-row not-stretched">
                            <div className="dashboard-column wide">

                                <div className="dashboard-card">

                                    <table className="approval-map-table">
                                        <thead>
                                            <tr>
                                                <th style={{width:'30%'}}>{ t('National Fire Situation Report') }</th>
                                                <th style={{width:'40%'}} className="right" colSpan="2">{ t('Status of Draft Copy') }, <span style={{opacity:0.5}}>{ t('as of') } { lastUpdated ? lastUpdated.format('LT') : '-' }</span></th>
                                                <th style={{width:'15%'}} className="right" colSpan="2">{ t('SitReps') }</th>
                                                <th style={{width:'15%'}} className="right" colSpan="2">{ t('Published Copy') }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { tableNationalSitrepStatuses }
                                        </tbody>
                                    </table>

                                </div>

                                <div className="dashboard-card">
                                    
                                    <table className="approval-map-table">
                                        <thead>
                                            <tr>
                                                <th style={{width:'30%'}}>{ t('Agency Sitreps') }</th>
                                                <th className="right" colSpan="2" style={{width:'40%'}}>{ t('Status of Draft Copy') }, <span style={{opacity:0.5}}>{ t('as of') } { lastUpdated ? lastUpdated.format('LT') : '-' }</span></th>
                                                <th style={{width:'15%'}} className="right" colSpan="2">{ t('Approved Copy') }</th>
                                                <th style={{width:'15%'}} className="right" colSpan="2">{ t('Published Copy') }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { tableCiffcSitrepStatuses }
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                            
                            <div className="dashboard-column">
                                <div className="dashboard-card flat">
                                    <table className="approval-map-table">
                                        <thead>
                                            <tr>
                                                <th>{ t('Fires') }</th>

                                                <th className="right">{ t('YTD') }</th>
                                                <th className="right">{ t('Today') }</th>
                                            </tr>
                                        </thead>
                                        { tableFireStats }
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        : null
                }

            </div>
        </section>
    );

}

export default AgencyOverview;